import React, { FC, useState, useEffect, useCallback } from "react";
import { Formik } from "formik";
import { useMutation, useQuery } from "react-apollo";
import gql from "graphql-tag";
import * as Yup from "yup";
import { PlainSelectInput, Spinner } from "@ovicare/ui";
import { Modal } from "components/Modal";

const CURRENT_AGENT_TIMEZONE = gql`
  query FetchTimeZoneInfo {
    me {
      id
      timeZoneName
      timeZoneNameConfirmedAt
    }
    timeZoneNames
  }
`;

interface Data {
  me: {
    timeZoneName: string;
    timeZoneNameConfirmedAt?: string;
  };
  timeZoneNames: string[];
}

const UPDATE_TIME_ZONE = gql`
  mutation UpdateMyTimeZone($timeZoneName: String!) {
    updateMyTimeZone(timeZoneName: $timeZoneName) {
      errors {
        key
        message
      }
      me {
        id
        timeZoneName
        timeZoneNameConfirmedAt
      }
    }
  }
`;

interface MutationData {
  updateMyTimeZone: {
    errors?: InputError[];
    me?: {
      id: string;
      timeZoneName: string;
      timeZoneNameConfirmedAt: string;
    };
  };
}

interface FormValues {
  timeZoneName: string;
}

/**
 * CaptureTimeZoneForm.
 */

interface CaptureTimeZoneFormProps {
  agentTimeZoneName: string;
  timeZoneNames: string[];
  onSuccess(): void;
}

export const CaptureTimeZoneForm: FC<CaptureTimeZoneFormProps> = props => {
  const { agentTimeZoneName, timeZoneNames, onSuccess } = props;
  const [updateTimeZone] = useMutation<MutationData>(UPDATE_TIME_ZONE);

  return (
    <div className="CaptureTimeZoneForm">
      <Formik<FormValues>
        initialValues={{ timeZoneName: agentTimeZoneName }}
        validationSchema={Yup.object().shape({
          timeZoneName: Yup.string().required("Required")
        })}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          setStatus({ errors: null });
          return updateTimeZone({
            variables: values,
            refetchQueries: [{ query: CURRENT_AGENT_TIMEZONE }]
          }).then(resp => {
            if (resp.data?.updateMyTimeZone.errors) {
              setStatus({ errors: resp.data.updateMyTimeZone.errors });
            } else if (resp.data?.updateMyTimeZone.me) {
              // it worked...
              return onSuccess();
            }
            setSubmitting(false);
          });
        }}
      >
        {({ values, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="mt-6 max-w-xs mx-auto">
              <label
                htmlFor="timeZoneName"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                My Time Zone
              </label>
              <div className="mt-1">
                <PlainSelectInput
                  name="timeZoneName"
                  options={(timeZoneNames || []).map(tz => ({
                    value: tz,
                    label: tz
                  }))}
                />
              </div>
            </div>

            <div className="mt-5 sm:mt-6 max-w-xs mx-auto">
              <span className="flex w-full rounded-md shadow-sm">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-gold-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-gold-500 focus:outline-none focus:border-gold-700 focus:shadow-outline-gold transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Set Time Zone
                </button>
              </span>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

/**
 * CaptureTimeZone.
 */
interface CaptureTimeZoneProps {}

export const CaptureTimeZone: FC<CaptureTimeZoneProps> = props => {
  const { loading, data, error } = useQuery<Data>(CURRENT_AGENT_TIMEZONE);
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = useCallback(() => setModalOpen(true), [setModalOpen]);
  const closeModal = useCallback(() => setModalOpen(false), [setModalOpen]);

  useEffect(() => {
    if (data?.me && !data?.me.timeZoneNameConfirmedAt) {
      openModal();
    }
  }, [data, openModal]);

  return (
    <Modal isOpen={modalOpen} onRequestClose={closeModal}>
      <h4 className="text-lg leading-6 font-medium text-gray-900">Welcome!</h4>
      <div className="mt-3">
        <p className="text-sm leading-5 text-gray-500 mt-3">
          Please set your time zone. You only have to do this once.
        </p>
        <p className="text-sm leading-5 text-gray-500 mt-3">
          You can also update your time zone any time by navigating to your
          settings.
        </p>
      </div>

      {loading ? (
        <div className="p-6 text-center">
          <Spinner />
        </div>
      ) : error || !(data && data.me && data.timeZoneNames) ? (
        <p>Failed to load. {JSON.stringify(error, null, 2)}</p>
      ) : (
        <CaptureTimeZoneForm
          onSuccess={closeModal}
          agentTimeZoneName={data.me.timeZoneName}
          timeZoneNames={data.timeZoneNames}
        />
      )}
    </Modal>
  );
};
