import React, { FC } from "react";
import { useLocation, Switch, useRouteMatch, Route } from "react-router-dom";
import { CaseInfoStep } from "./CaseInfoStep";
import { QuestionsStep } from "./QuestionsStep";
import { ResultsStep } from "./ResultsStep";
import { ConfirmationStep } from "./ConfirmationStep";
import { NavigationSteps, NavigationStepModel } from "./NavigationSteps";
import { Container } from "@ovicare/ui";

const steps: NavigationStepModel[] = [
  {
    label: "Case Info",
    icon: "pencil-alt",
  },
  {
    label: "Questions",
    icon: "clipboard-list",
  },
  {
    label: "Schedule",
    icon: "calendar",
  },
  {
    label: "Confirmation",
    icon: "check-circle",
  },
];

function getCurrentStep(pathname: string) {
  if (pathname.includes("/new")) return 0;
  if (pathname.includes("/questions")) return 1;
  if (pathname.includes("/results")) return 2;
  if (pathname.includes("/confirm")) return 3;
  return -1;
}

interface NewP2PRequestScreenProps {}

export const NewP2PRequestScreen: FC<NewP2PRequestScreenProps> = () => {
  const location = useLocation();
  const match = useRouteMatch();

  return (
    <div className="_NewP2PRequestScreen py-6">
      <Container>
        <div className="lg:px-24 max-w-4xl px-4 mx-auto">
          <NavigationSteps
            steps={steps}
            currentStep={getCurrentStep(location.pathname)}
          />
        </div>
        <Switch>
          <Route path={`${match.path}/new`}>
            <CaseInfoStep />
          </Route>
          <Route path={`${match.path}/:appointmentRequestId/questions`}>
            <QuestionsStep />
          </Route>
          <Route path={`${match.path}/:appointmentRequestId/results`}>
            <ResultsStep />
          </Route>
          <Route path={`${match.path}/:appointmentRequestId/confirm`}>
            <ConfirmationStep />
          </Route>
          <Route path="*">
            <h1>Not Found</h1>
          </Route>
        </Switch>
      </Container>
    </div>
  );
};
