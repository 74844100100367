import React, { FC } from "react";
import { AppointmentStatus } from "screens/MyP2PRequestsScreen/types";
import { AppointmentModel } from "../types";
import { appointmentStatus } from "screens/MyP2PRequestsScreen/helpers";
import { statusLabels } from "../P2PRequestShowScreen";
import { FAIcon } from "@ovicare/ui";

const statusLabelClassNames: Record<AppointmentStatus, string> = {
  [AppointmentStatus.Cancelled]: "text-red-800",
  [AppointmentStatus.Missed]: "text-red-800",
  [AppointmentStatus.Scheduled]: "text-blue-800",
  [AppointmentStatus.Completed]: "text-green-800"
};

const statusLabelIconClassNames: Record<AppointmentStatus, string> = {
  [AppointmentStatus.Cancelled]: "text-red-400",
  [AppointmentStatus.Missed]: "text-red-400",
  [AppointmentStatus.Scheduled]: "text-blue-400",
  [AppointmentStatus.Completed]: "text-green-400"
};

interface QuickAppointmentDetailsProps {
  appointment: AppointmentModel;
}

export const QuickAppointmentDetails: FC<QuickAppointmentDetailsProps> = props => {
  const { appointment } = props;

  const status = appointment ? appointmentStatus(appointment) : null;
  const statusLabel = status ? statusLabels[status] : null;
  const statusLabelClassName = status ? statusLabelClassNames[status] : "";
  const statusLabelIconClassName = status
    ? statusLabelIconClassNames[status]
    : "";

  return (
    <div className="mt-3 px-4 text-lg">
      <p className={`font-semibold uppercase ${statusLabelClassName}`}>
        <span className={`inline-block mr-3 ${statusLabelIconClassName}`}>
          <FAIcon icon="info-circle" />
        </span>
        {statusLabel}
      </p>
      {appointment?.requiresReschedule ? (
        <p className="text-yellow-600">
          <span className="inline-block mr-3 text-yellow-400">
            <FAIcon icon="exclamation-triangle" />
          </span>
          Reschedule Required
        </p>
      ) : null}
      <p>
        <span className="inline-block mr-3 text-gray-600">
          <FAIcon icon={["far", "calendar-alt"]} />
        </span>
        {appointment?.startDateString}
      </p>
      <p>
        <span className="inline-block mr-3 text-gray-600">
          <FAIcon icon={["far", "clock"]} />
        </span>
        {appointment?.startTimeString}
      </p>
      <p>
        <span className="inline-block mr-3 text-gray-600">
          <FAIcon icon="user-md" />
        </span>
        {appointment?.receivingProvider.nameWithAppellation}
      </p>
    </div>
  );
};
