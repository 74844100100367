import React, { FC } from "react";
import cx from "classnames";
import { FAIcon } from "@ovicare/ui";
import union from "lodash/union";
import difference from "lodash/difference";
import flatten from "lodash/flatten";
import "./PreferredTimes.css";

export type TimeOption = string;

export type TimeSet = {
  setName: "Morning" | "Afternoon";
  times: TimeOption[];
};

export const defaultTimes: TimeSet[] = [
  {
    setName: "Morning",
    times: [
      "7:00 to 8:00",
      "8:00 to 9:00",
      "9:00 to 10:00",
      "10:00 to 11:00",
      "11:00 to 12:00",
    ],
  },
  {
    setName: "Afternoon",
    times: [
      "12:00 to 1:00",
      "1:00 to 2:00",
      "2:00 to 3:00",
      "3:00 to 4:00",
      "4:00 to 5:00",
      "5:00 to 6:00",
      "6:00 to 7:00",
    ],
  },
];

export const flatDefaultTimes = flatten(defaultTimes.map((ts) => ts.times));

interface PreferredTimesProps {
  id?: string;
  times?: TimeSet[];
  selectedTimes: TimeOption[];
  onChange?(selectedTimes: TimeOption[]): void;
  showInstructions?: boolean;
}

export const PreferredTimes: FC<PreferredTimesProps> = (props) => {
  const {
    id,
    times = defaultTimes,
    selectedTimes,
    onChange,
    showInstructions = false,
  } = props;

  function handleTimeClick(time: string) {
    if (onChange) {
      const selected = selectedTimes.includes(time)
        ? selectedTimes.filter((t) => t !== time)
        : selectedTimes.concat(time);
      onChange(selected);
    }
  }

  function handleTimeSetClick(setName: "Morning" | "Afternoon") {
    if (onChange) {
      // Find the relevant TimeSet
      const timeSet = times.find((ts) => ts.setName === setName) || {
        times: [],
      };
      const setTimes = timeSet.times;

      // Determine if they're all checked or not:
      const allChecked =
        union(selectedTimes, setTimes).length === selectedTimes.length;

      // Compute the updated set of selected values:
      const selected = allChecked
        ? difference(selectedTimes, setTimes)
        : union(selectedTimes, setTimes);
      onChange(selected);
    }
  }

  return (
    <div id={id} className="PreferredTimes">
      <div className="PreferredTimes__columns">
        {times.map((ts) => (
          <div key={ts.setName} className="PreferredTimes__column">
            <div
              className="PreferredTimes__cell PreferredTimes__set_header"
              onClick={() => handleTimeSetClick(ts.setName)}
            >
              {ts.setName}
            </div>
            <div className="PreferredTimes__columns">
              {ts.times.map((time) => (
                <div
                  key={time}
                  className="PreferredTimes__column"
                  onClick={() => handleTimeClick(time)}
                >
                  <div className="PreferredTimes__cell PreferredTimes__time_header">
                    {time}
                  </div>
                  <div
                    className={cx(
                      "PreferredTimes__cell PreferredTimes__time_body",
                      {
                        PreferredTimes__selected: selectedTimes.includes(time),
                        PreferredTimes__unselected: !selectedTimes.includes(
                          time
                        ),
                      }
                    )}
                  >
                    <FAIcon
                      icon={selectedTimes.includes(time) ? "check" : "times"}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
        {showInstructions ? (
          <div className="PreferredDays__instructions_container">
            <div className="PreferredDays__instructions">
              <span style={{ marginRight: "0.75em" }}>
                <FAIcon icon="arrow-left" />
              </span>
              Click to edit
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
