export interface AppointmentRequestsFilterModel {
  subset: AppointmentRequestSubset;
  caseReferenceNumber?: string;
}

export enum AppointmentStatus {
  Scheduled = "SCHEDULED",
  Completed = "COMPLETED",
  Missed = "MISSED",
  Cancelled = "CANCELLED"
}

export enum AppointmentRequestSubset {
  All = "ALL",
  AllOpen = "ALL_OPEN",
  Reschedule = "RESCHEDULE",
  Scheduled = "SCHEDULED",
  Completed = "COMPLETED",
  Cancelled = "CANCELLED",
  Missed = "MISSED",
  Denied = "DENIED",
  Past = "PAST"
}

export interface AppointmentRequestModel {
  id: string;
  insertedAt: string;
  createdByUser?: {
    id: string;
    firstName: string;
    lastName: string;
  };
  createdByAgent?: {
    id: string;
    firstName: string;
    lastName?: string;
  };
  caseProfiles: CaseProfileModel[];
  appointments: AppointmentModel[];
}

export interface AppointmentModel {
  id: string;
  completed: boolean;
  cancelled: boolean;
  missed: boolean;
  requiresReschedule: boolean;
  startTimeString: string;
  timeRange: {
    start: string;
    finish: string;
  };
  insertedAt: string;
}

export interface CaseProfileModel {
  id: string;
  memberFirstName: string;
  memberLastName: string;
  memberDob: string;
  caseReferenceNumber: string;
  insurancePlanCode?: string;
  levelOfReview: {
    value: string;
    label: string;
  };
  modality: {
    id: string;
    name: string;
  };
  healthPlan: {
    id: string;
    name: string;
  };
  memberState: {
    id: string;
    name: string;
    abbreviation: string;
  };
}
