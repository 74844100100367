import { FC } from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { useAuth } from "context/AuthContext";
import { FAIcon } from "@ovicare/ui";
import { useDropdown } from "hooks/useDropdown";
import { ZoomIn } from "components/Animations";

const CURRENT_AGENT = gql`
  query CurrentAgent {
    me {
      id
      firstName
      lastName
      timeZoneName
      email
    }
  }
`;

interface Data {
  me: {
    id: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    timeZoneName: string;
  };
}

interface CurrentAgentMenuProps { }

export const CurrentAgentMenu: FC<CurrentAgentMenuProps> = () => {
  const { isOpen, toggle, triggerRef, contentRef } = useDropdown();

  const { data, loading, error } = useQuery<Data>(CURRENT_AGENT);
  const name = data?.me.firstName || data?.me.lastName;
  const initial = name ? name.slice(0, 1) : "-";
  const nameLabel = loading ? "Loading..." : name ?? "-";
  const email = loading ? "Loading..." : data?.me?.email ?? "-";

  const { logout } = useAuth();

  return (
    <div className="_CurrentAgentMenu relative inline-block mx-auto">
      <div>
        <span className="rounded-lg shadow-sm">
          <button
            type="button"
            ref={triggerRef}
            onClick={toggle}
            className="hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 inline-flex items-center justify-center w-full p-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-lg"
          >
            {error ? (
              <p>Failed to load</p>
            ) : (
              <>
                <p className="flex items-center justify-center w-8 h-8 text-lg leading-none text-indigo-700 bg-indigo-200 rounded-lg shadow-inner">
                  {initial}
                </p>
                <p className="sm:block flex-1 hidden ml-3 mr-4 text-left text-gray-800 truncate">
                  {nameLabel}
                </p>
                <div className="ml-3 mr-2 text-gray-600">
                  <FAIcon icon="chevron-down" />
                </div>
              </>
            )}
          </button>
        </span>
      </div>

      <ZoomIn
        ref={contentRef}
        show={isOpen}
        className="absolute right-0 w-56 mt-2 origin-top-right rounded-md shadow-lg"
      >
        <div className="bg-white rounded-md shadow-xs">
          <div className="px-4 py-3">
            <p className="text-sm leading-5">Signed in as</p>
            <p className="text-sm font-medium leading-5 text-gray-900">
              {email}
            </p>
          </div>
          <div className="border-t border-gray-100"></div>
          {/* <div className="py-1">
            <a
              href="#"
              className="hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 block px-4 py-2 text-sm leading-5 text-gray-700"
            >
              Account settings
            </a>
            <a
              href="#"
              className="hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 block px-4 py-2 text-sm leading-5 text-gray-700"
            >
              Support
            </a>
            <a
              href="#"
              className="hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 block px-4 py-2 text-sm leading-5 text-gray-700"
            >
              License
            </a>
          </div>
          <div className="border-t border-gray-100"></div> */}
          <div className="py-1">
            <button
              type="button"
              onClick={logout}
              className="hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 block w-full px-4 py-2 text-sm leading-5 text-left text-gray-700"
            >
              Sign out
            </button>
          </div>
        </div>
      </ZoomIn>
    </div>
  );
};
