import React, { FC } from "react";
import { useField, ErrorMessage } from "formik";
import { Radio, RadioGroup, RadioGroupProps } from "../../RadioGroup/RadioGroup";
import {
  VerticalField,
  HorizontalField,
} from '../../FieldStructure/FieldStructure';
import { StandardOption } from "../SelectField/SelectField";

/**
 * RadioGroupInput.
 */

export interface RadioGroupInputProps {
  name: string;
  id?: string;
  className?: string;
  inline?: boolean;
  options: StandardOption[];
  inputProps?: RadioGroupProps<string>;
}

export const RadioGroupInput: FC<RadioGroupInputProps> = (props) => {
  const { name, id, className, inline, options, inputProps = {} } = props;

  const [{ onChange, onBlur, ...field }, meta] = useField(name);

  const showError = !!(meta && meta.touched && meta.error);

  const controlClassName = [
    'w-full',
    showError && '-m-2 p-2 border border-red-500 rounded',
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div className="max-w-lg">
      <div className={controlClassName}>
        <RadioGroup
          {...field}
          labelledBy={`field--${id || name}`}
          onChange={onChange(name)}
          className={className}
          inline={inline}
          {...inputProps}
        >
          {options.map((option) => (
            <Radio key={option.value} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </RadioGroup>
      </div>
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-xs italic text-red-500"
      />
    </div>
  );
};

/**
 * RadioGroupField.
 */

export interface RadioGroupFieldProps extends RadioGroupInputProps {
  label: string;
  indicateOptional?: boolean;
}

export const RadioGroupField: FC<RadioGroupFieldProps> = (props) => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <RadioGroupInput {...rest} />
    </VerticalField>
  );
};

export const HorizontalRadioGroupField: FC<RadioGroupFieldProps> = (props) => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <RadioGroupInput {...rest} />
    </HorizontalField>
  );
};
