import React, { FC } from "react";
import { Modal } from "components/Modal";
import { SlotTakenNotice } from "./SlotTakenNotice";

interface NoLongerAvailableModalProps {
  isOpen: boolean;
  onRequestClose(): void;
  onAcknowledge(): void;
}

export const NoLongerAvailableModal: FC<NoLongerAvailableModalProps> = props => {
  const { isOpen, onRequestClose, onAcknowledge } = props;
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <SlotTakenNotice onAcknowledge={onAcknowledge} />
    </Modal>
  );
};
