import React, { FC } from "react";
import { useAuth } from "context/AuthContext";
import { Route, RouteProps, Redirect, useLocation } from "react-router-dom";

type PrivateRouteProps = {} & RouteProps;

export const PrivateRoute: FC<PrivateRouteProps> = props => {
  const { token } = useAuth();
  const location = useLocation();

  if (!token) {
    return (
      <Redirect
        to={{
          pathname: "/auth/sign_in",
          state: { from: location }
        }}
      />
    );
  }

  return <Route {...props} />;
};
