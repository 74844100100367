import React, { FC, useState, useCallback } from "react";
import { ProviderResults } from "screens/NewP2PRequestScreen/ResultsStep/ProviderResults";
import { ProviderSlotSelectionModel } from "screens/NewP2PRequestScreen/ResultsStep/ProviderResults/types";
import { AppointmentModel } from "../types";
import { Modal, ModalHeader } from "components/Modal";
import { RescheduleForm } from "./RescheduleForm";

interface RescheduleAppointmentModalProps {
  onClose(): void;
  isOpen: boolean;
  appointmentRequestId: string;
  appointment?: AppointmentModel;
  refetchQueries: any;
}

export const RescheduleAppointmentModal: FC<RescheduleAppointmentModalProps> = (
  props
) => {
  const {
    isOpen,
    onClose,
    appointmentRequestId,
    appointment,
    refetchQueries,
  } = props;
  const [
    selectedSlot,
    setSelectedSlot,
  ] = useState<ProviderSlotSelectionModel | null>(null);

  const forgetSlot = useCallback(() => {
    setSelectedSlot(null);
  }, [setSelectedSlot]);

  const closeAndForgetSlot = useCallback(() => {
    forgetSlot();
    onClose();
  }, [forgetSlot, onClose]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeAndForgetSlot}
      className="max-w-7xl"
    >
      <ModalHeader icon="calendar-alt" title="Reschedule Appointment" />
      {appointment && (
        <div className="_RescheduleAppointmentModal px-4 pb-5">
          {!selectedSlot ? (
            <ProviderResults
              appointmentRequestId={appointmentRequestId}
              onSlotClick={setSelectedSlot}
            />
          ) : (
            <RescheduleForm
              selectedSlot={selectedSlot}
              appointment={appointment}
              refetchQueries={refetchQueries}
              onSuccess={closeAndForgetSlot}
              unselectSlot={forgetSlot}
            />
          )}
        </div>
      )}
    </Modal>
  );
};
