import React, { FC, useCallback } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import qs from "query-string";
import { ProviderResults } from "./ProviderResults";
import { ProviderSlotSelectionModel } from "./ProviderResults/types";

interface RouteParams {
  appointmentRequestId: string;
}

interface ResultsStepProps {}

export const ResultsStep: FC<ResultsStepProps> = () => {
  const history = useHistory();
  const match = useRouteMatch<RouteParams>();
  const { appointmentRequestId } = match.params;

  const handleSlotClick = useCallback(
    (selectedSlot: ProviderSlotSelectionModel) => {
      const confirmationPath = confirmationStepPath(
        appointmentRequestId,
        selectedSlot
      );
      history.push(confirmationPath);
    },
    [appointmentRequestId, history]
  );

  return (
    <div className="ResultsStep mt-4">
      <ProviderResults
        appointmentRequestId={appointmentRequestId}
        onSlotClick={handleSlotClick}
      />
    </div>
  );
};

function confirmationStepPath(
  appointmentRequestId: string,
  selectedSlot: ProviderSlotSelectionModel
) {
  const {
    provider: { id: providerId },
    slot: {
      timeRange: {
        start: startTime,
        startTimeString,
        finish: finishTime,
        finishTimeString
      }
    }
  } = selectedSlot;
  return `/cw/p2p_request_wizard/${appointmentRequestId}/confirm?${qs.stringify(
    {
      startTime,
      startTimeString,
      finishTime,
      finishTimeString,
      providerId
    }
  )}`;
}
