import React, { FC } from "react";
import { ExclamationCircle_md as ExclamationCircle } from "components/Heroicons";

interface SlotTakenNoticeProps {
  onAcknowledge(): void;
}

export const SlotTakenNotice: FC<SlotTakenNoticeProps> = props => {
  const { onAcknowledge } = props;
  return (
    <div className="_SlotTakenNotice">
      <div>
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-orange-100">
          <ExclamationCircle className="h-6 w-6 text-orange-600" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Selected time no longer available
          </h3>
          <div className="mt-2">
            <p className="text-sm leading-5 text-gray-500">
              Unfortunately your selected P2P time is no longer available.
              Please select a new time.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6">
        <span className="flex w-full rounded-md shadow-sm">
          <button
            onClick={onAcknowledge}
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-gold-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-gold-500 focus:outline-none focus:border-gold-700 focus:shadow-outline-yellow transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            Go back to results
          </button>
        </span>
      </div>
    </div>
  );
};
