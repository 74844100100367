import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

const DetailListItem = styled.div`
  &:first-child {
    margin-top: 0;
  }
`;

/**
 * DetailItem.
 */

interface DetailItemProps {
  label: string;
  labelWidth?: number;
}

export const DetailItem: FC<DetailItemProps> = props => {
  const { label, children, labelWidth = 96 } = props;
  return (
    <DetailListItem className="_DetailItem mt-4 sm:mt-3 sm:flex leading-none items-baseline">
      <div
        className="font-semibold sm:text-right text-gray-700 text-xs"
        style={{ minWidth: labelWidth }}
      >
        {label}
      </div>
      <div className="mt-2 sm:mt-0 sm:ml-4 text-lg text-gray-900 flex-1">
        {children}
      </div>
    </DetailListItem>
  );
};

/**
 * DetailList.
 */

interface DetailListProps extends HTMLAttributes<HTMLDivElement> {}

export const DetailList: FC<DetailListProps> = props => {
  const { className, ...rest } = props;
  return <div className={`DetailList ${className || ''}`} {...rest} />;
};
