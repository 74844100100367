import React, { FC } from "react";
import evicoreLogoSrc from "images/eviCore_big_logo.png";

export const SignInScreen: FC = () => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-32 w-auto"
          src={evicoreLogoSrc}
          alt="Workflow"
        />
        <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
          Sign in to your account
        </h2>
        <p className="mt-2 text-center text-sm leading-5 text-gray-600 max-w">
          Sign in to access the eviCore P2P portal.
        </p>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <button>Sign In</button>
      </div>
    </div>
  );
};
