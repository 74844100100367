import React, { FC, useState, useEffect } from "react";
import { useLazyQuery } from "react-apollo";
import gql from "graphql-tag";
import { Spinner } from "@ovicare/ui";
import { ISODateToLocal } from "@ovicare/common";
import { useLocation, useHistory } from "react-router-dom";
import qs from "query-string";
import {
  CaseLookupsForm,
  FormValues as CaseLookupsFormModel,
} from "./CaseLookupsForm";
import logoSrc from "images/eviCore_big_logo.png";
import { ScreenTitle } from "context/ScreenTitle";
import { NewAppointmentRequestForm } from "./NewAppointmentRequestForm";
import { Transition } from "react-transition-group";

/**
 * StepHeader.
 */

const StepHeader: FC = () => {
  return (
    <div className="flex items-center max-w-3xl mx-auto">
      <div className="flex-1 px-2">
        <h1 className="font-bold text-2xl md:text-3xl text-gray-700">
          New P2P Request
        </h1>
      </div>
      <div className="flex-0 md:py-4 px-2">
        <img className="h-16 md:h-24 w-auto" src={logoSrc} alt="eviCore logo" />
      </div>
    </div>
  );
};

const SSO_CASE_PAYLOAD = gql`
  query GetSSOCasePayload($id: UUID4!) {
    ssoCasePayload(id: $id) {
      caseNumber
      lineOfBusiness
      externalSystemName
      patientDob
      dateOfService
      isPortal
      agent {
        id
      }
      healthPlan {
        id
        name
      }
    }
  }
`;

interface Data {
  ssoCasePayload: SSOCasePayloadModel;
}

interface SSOCasePayloadModel {
  caseNumber?: string;
  lineOfBusiness?: string;
  externalSystemName?: string;
  patientDob?: string;
  dateOfService?: string;
  isPortal?: boolean;
  agent: {
    id: string;
  };
  healthPlan?: {
    id: string;
    name: string;
  };
}

export interface CaseLookupParams {
  healthPlanId: string;
  lineOfBusiness: string;
  caseReferenceNumber: string;
  memberDob: string;
  dateOfService?: string;
  isPortal?: boolean;
}

const transitionClassNames = {
  entering: "opacity-0 scale-75 translate-y-32",
  entered: "opacity-100 scale-100 translate-x-0",
  exiting: "opacity-0 scale-75 translate-y-32",
  exited: "opacity-0 scale-75 translate-y-32",
};

interface CaseInfoStepProps {}

export const CaseInfoStep: FC<CaseInfoStepProps> = () => {
  const history = useHistory();
  const location = useLocation();
  const { payload_id: payloadId } = qs.parse(location.search);

  const [loadSSOCasePayload, { data, loading, error }] = useLazyQuery<Data>(
    SSO_CASE_PAYLOAD
  );

  useEffect(() => {
    if (payloadId) {
      loadSSOCasePayload({ variables: { id: payloadId } });
    }
  }, [loadSSOCasePayload, payloadId]);

  const [caseLookups, setCaseLookups] = useState<null | CaseLookupParams[]>(
    null
  );

  function handleSubmit(formValues: CaseLookupsFormModel) {
    setCaseLookups(formValues.caseLookups);
  }

  // const springProps = useSpring({
  //   from: { opacity: 0, transform: `scale(0.8) translateY(20%)` },
  //   to: { opacity: 1, transform: `scale(1) translateY(0)` }
  // });

  return (
    <div className="CaseInfoStep max-w-5xl mx-auto">
      <ScreenTitle title="New P2P Request" />
      <Transition appear in timeout={0}>
        {(state) => (
          <div
            className={`_ZoomIn transform transition-all ease-in-out duration-500 ${transitionClassNames[state]}`}
          >
            <div className="bg-white mt-4 px-2 py-4 rounded-lg shadow-xl">
              <StepHeader />
              {caseLookups && caseLookups.length > 0 ? (
                <NewAppointmentRequestForm
                  caseLookups={caseLookups}
                  onCreate={(appointmentRequestId) => {
                    return history.push(
                      `/cw/p2p_request_wizard/${appointmentRequestId}/questions`
                    );
                  }}
                />
              ) : payloadId ? (
                loading ? (
                  <div className="p-6 text-center">
                    <Spinner />
                  </div>
                ) : error || !(data && data.ssoCasePayload) ? (
                  <p>Failed to load.</p>
                ) : (
                  <CaseLookupsForm
                    onSubmit={handleSubmit}
                    initialValues={{
                      caseLookups: [toParams(data.ssoCasePayload)],
                    }}
                  />
                )
              ) : (
                <CaseLookupsForm onSubmit={handleSubmit} />
              )}
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
};

function toParams(ssoCasePayload: SSOCasePayloadModel): CaseLookupParams {
  return {
    healthPlanId: ssoCasePayload.healthPlan?.id || "",
    lineOfBusiness: ssoCasePayload.lineOfBusiness || "",
    caseReferenceNumber: ssoCasePayload.caseNumber || "",
    dateOfService: ssoCasePayload.dateOfService || "",
    isPortal: !!ssoCasePayload.isPortal,
    memberDob:
      (ssoCasePayload.patientDob &&
        ISODateToLocal(ssoCasePayload.patientDob)) ||
      "",
  };
}
