import React, { FC, useState, ReactElement, useCallback } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import {
  AppointmentRequestModel,
  AppointmentRequestsFilterModel as FilterModel,
  AppointmentRequestSubset as Subset,
} from "./types";
import { parseISO, lightFormat } from "date-fns";
import { translateLOR } from "@ovicare/common";
import { makeAppendItems } from "lib/makeAppendItems";
import { Link } from "react-router-dom";
import { ScreenTitle } from "context/ScreenTitle";
import { FAIcon, Spinner, Button } from "@ovicare/ui";
import {
  requestIsCancelled,
  requestIsMissed,
  requestIsCompleted,
  getCurrentAppointment,
  filterIsOpen,
} from "./helpers";
import { StatusBadge } from "./StatusBadge";
import { Search_md as SearchIcon } from "components/Heroicons";
// import { FilterPanel } from "./FilterPanel";

const PAGE_SIZE = 25;

const REQUESTS_QUERY = gql`
  query GetAppointmentRequests(
    $first: Int!
    $after: String
    $filter: AppointmentRequestsFilter!
  ) {
    appointmentRequests(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        insertedAt
        createdByUser {
          id
          firstName
          lastName
        }
        createdByAgent {
          id
          firstName
          lastName
        }
        caseProfiles {
          id
          memberFirstName
          memberLastName
          memberDob
          caseReferenceNumber
          insurancePlanCode
          levelOfReview {
            value
            label
          }
          modality {
            id
            name
          }
          healthPlan {
            id
            name
          }
          memberState {
            id
            name
            abbreviation
          }
        }
        appointments {
          id
          completed
          cancelled
          missed
          requiresReschedule
          startTimeString(format: "{h12}:{m} {am} {Zabbr}")
          timeRange {
            start
            finish
          }
          insertedAt
        }
      }
    }
  }
`;

interface Data {
  appointmentRequests: Paginated<AppointmentRequestModel>;
}

/**
 * NoResults.
 */

interface NoResultsProps {
  filter: FilterModel;
  resetFilter(): void;
}

const NoResults: FC<NoResultsProps> = (props) => {
  const { filter, resetFilter } = props;
  const isFilterOpen = filterIsOpen(filter);

  return (
    <div className="_NoResults py-8">
      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100">
        <SearchIcon className="h-6 w-6 text-indigo-600" />
      </div>
      <div className="mt-3 text-center sm:mt-5">
        <h3 className="text-lg leading-6 font-medium text-indigo-900">
          No P2P Requests
        </h3>
        {!isFilterOpen && (
          <div className="mt-2">
            <Button type="button" onClick={resetFilter}>
              Clear filters
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

/**
 * AppointmentRequestRow.
 */

interface AppointmentRequestRowProps {
  appointmentRequest: AppointmentRequestModel;
}

const AppointmentRequestRow: FC<AppointmentRequestRowProps> = (props) => {
  const { appointmentRequest } = props;
  const { id, caseProfiles } = appointmentRequest;

  const names = caseProfiles
    .map((cp) => `${cp.memberFirstName} ${cp.memberLastName}`)
    .join(", ");

  return (
    <li>
      <Link
        to={`/cw/requests/${id}`}
        className="block hover:bg-gray-50 border-b border-gray-200 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
      >
        <div className="flex items-center px-2 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0 w-20 font-semibold text-xs text-gray-700">
              {caseProfiles.map((cp) => (
                <p key={cp.id}>{cp.caseReferenceNumber}</p>
              ))}
            </div>
            <div className="min-w-0 flex-1 px-2 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <div className="text-base leading-5 font-medium text-blue-600 truncate">
                  {names}
                </div>
                <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
                  <div
                    className={`flex-shrink-0 mr-1 text-base h-5 w-5 ${colorClassNameForRequest(
                      appointmentRequest
                    )}`}
                  >
                    <FAIcon icon={iconForRequest(appointmentRequest)} />
                  </div>
                  <span className="flex-shrink-0 truncate font-semibold">
                    {startTimeString(appointmentRequest)}
                  </span>
                  <div className="flex-1 sm:ml-4 sm:text-left text-right">
                    <StatusBadge
                      appointment={getCurrentAppointment(appointmentRequest)}
                      className="px-2 py-1 leading-none text-xs"
                    />
                  </div>
                </div>
              </div>
              <div className="hidden items-center md:flex">
                <div className="text-base leading-5 text-gray-700 font-bold">
                  <span className="text-sm font-semibold text-gray-500 mr-2">
                    Level of Review:
                  </span>
                  {levelOfReviewLabelFor(appointmentRequest)}
                </div>
              </div>
            </div>
          </div>
          <div>
            <svg
              className="h-5 w-5 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      </Link>
    </li>
  );
};

const initialFilter: FilterModel = {
  subset: Subset.All,
};

interface MyP2PRequestsScreenProps {}

export const MyP2PRequestsScreen: FC<MyP2PRequestsScreenProps> = () => {
  const [filter, setFilter] = useState<FilterModel>(initialFilter);
  const { data, loading, error, fetchMore } = useQuery<Data>(REQUESTS_QUERY, {
    variables: { first: PAGE_SIZE, filter },
  });

  const resetFilter = useCallback(() => {
    setFilter(initialFilter);
  }, [setFilter]);

  return (
    <>
      <ScreenTitle title="P2P Requests" />
      <div className="MyP2PRequestsScreen max-w-7xl mx-auto mt-4 sm:px-4">
        {/* <FilterPanel value={filter} onChange={setFilter} fetching={loading} /> */}
        <div className="my-4 sm:my-6">
          {loading ? (
            <div className="p-8 text-center">
              <Spinner />
            </div>
          ) : error || !(data && data.appointmentRequests) ? (
            <div>
              <p>Failed to load.</p>
            </div>
          ) : (
            <div className="bg-white shadow overflow-hidden sm:rounded-md">
              {data.appointmentRequests.items.length === 0 ? (
                <NoResults filter={filter} resetFilter={resetFilter} />
              ) : (
                <>
                  <ul>
                    {data.appointmentRequests.items.map((ar) => (
                      <AppointmentRequestRow
                        key={ar.id}
                        appointmentRequest={ar}
                      />
                    ))}
                  </ul>
                  <div className="flex p-4 justify-center">
                    {!data.appointmentRequests.endOfList ? (
                      <button
                        type="button"
                        className="border btn btn-gray-alt btn-sm duration-150 ease-in-out hover:bg-blue-50 max-w-md px-2 py-1 rounded-lg transition-colors w-full"
                        onClick={() =>
                          fetchMore({
                            query: REQUESTS_QUERY,
                            variables: {
                              first: PAGE_SIZE,
                              after: data.appointmentRequests.cursor,
                              filter,
                            },
                            updateQuery,
                          })
                        }
                      >
                        Load More
                      </button>
                    ) : (
                      <p className="text-gray-500">End of List</p>
                    )}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const updateQuery = makeAppendItems<Data>("appointmentRequests");

function colorClassNameForRequest(appointmentRequest: AppointmentRequestModel) {
  if (
    requestIsCancelled(appointmentRequest) ||
    requestIsMissed(appointmentRequest)
  ) {
    return "text-red-500";
  } else if (requestIsCompleted(appointmentRequest)) {
    return "text-green-500";
  }
  return "text-gray-500";
}

function iconForRequest(appointmentRequest: AppointmentRequestModel): IconProp {
  if (
    requestIsCancelled(appointmentRequest) ||
    requestIsMissed(appointmentRequest)
  ) {
    return ["far", "calendar-times"];
  } else if (requestIsCompleted(appointmentRequest)) {
    return ["far", "calendar-check"];
  }
  return ["far", "calendar-alt"];
}

function startTimeString(appointmentRequest: AppointmentRequestModel): string {
  const currentAppointment = getCurrentAppointment(appointmentRequest);
  const { start } = currentAppointment.timeRange;
  const startTime = parseISO(start);
  return lightFormat(startTime, "M/d/yy - h:mm a");
}

function levelOfReviewLabelFor(
  appointmentRequest: AppointmentRequestModel
): ReactElement {
  const allLORs = appointmentRequest.caseProfiles.map((cp) =>
    translateLOR(
      cp.levelOfReview.label,
      cp.insurancePlanCode,
      cp.memberState.name
    )
  );
  const uniqueLORs = [...new Set(allLORs)];
  const extra =
    uniqueLORs.length > 1 ? (
      <span className="ml-2 font-normal">+{uniqueLORs.length - 1} others</span>
    ) : null;

  return (
    <>
      {uniqueLORs[0]}
      {extra}
    </>
  );
}
