import React, { FC, ReactNode, CSSProperties } from 'react';
import { AppColor } from '../../styles/colorStyles';
import styled from 'styled-components';

const HoverTarget = styled.span`
  position: relative;
`;

const StyledTooltip = styled.span`
  ${HoverTarget} > & {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -85%);
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    display: table;
    font-size: 0.875rem;
    font-family: sans-serif;
    text-align: center;
    border-radius: 0.375rem;
    padding: 0.25rem 0.75rem;
    transition: transform 150ms, opacity 150ms, visibility 150ms;
  }

  ${HoverTarget}:hover > & {
    transform: translate(-50%, -135%);
    opacity: 1;
    visibility: visible;
  }
`;

const FrontTriangle = styled.span`
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translate(-50%, 100%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-top: 10px solid currentColor;
`;

const BackTriangle = styled.span`
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translate(-50%, 100%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid currentColor;
`;

function bodyClassNames(color: string): string {
  switch (color) {
    case 'blue':
      return 'bg-blue-100 border-blue-300 text-blue-700';
    case 'purple':
      return 'bg-purple-100 border-purple-300 text-purple-700';
    default:
      return '';
  }
}

function lightTriangleClassName(color: string): string {
  switch (color) {
    case 'blue':
      return 'text-blue-100';
    case 'purple':
      return 'text-purple-100';
    default:
      return '';
  }
}

function darkTriangleClassName(color: string): string {
  switch (color) {
    case 'blue':
      return 'text-blue-300';
    case 'purple':
      return 'text-purple-300';
    default:
      return '';
  }
}

interface TooltipProps {
  tip: ReactNode;
  color?: AppColor;
  onMouseLeave?(): void;
  style?: CSSProperties;
}

export const Tooltip: FC<TooltipProps> = props => {
  const { tip, children, onMouseLeave, color = 'blue', style } = props;

  return (
    <HoverTarget onMouseLeave={onMouseLeave}>
      {children}
      <StyledTooltip style={style} className={bodyClassNames(color)}>
        <span className="block relative">
          <span>{tip}</span>
          <BackTriangle className={darkTriangleClassName(color)} />
          <FrontTriangle className={lightTriangleClassName(color)} />
        </span>
      </StyledTooltip>
    </HoverTarget>
  );
};
