import React, { FC } from "react";
import { ApolloProvider as ReactApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { onError, ErrorResponse } from "apollo-link-error";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  NormalizedCacheObject
} from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { tokenStore, useAuth } from "./AuthContext";
import introspectionQueryResultData from "../fragmentTypes.json";

const httpOpts = {
  uri: `/graphql/api`
};

const httpLink = createHttpLink(httpOpts);

const authLink = setContext((_, { headers }) => {
  const token = tokenStore.getStoredToken();
  return token && token.jwt
    ? { headers: { ...headers, authorization: `Bearer ${token.jwt}` } }
    : { headers };
});

// Neet to run `mix extract_graphql_fragments` whenever you change Fragments.
// https://www.apollographql.com/docs/react/advanced/fragments.html
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const cache = new InMemoryCache({ fragmentMatcher });

function clientFactory(
  logout: () => void
): ApolloClient<NormalizedCacheObject> {
  function errorHandler(error: ErrorResponse): void {
    if (error.graphQLErrors) {
      error.graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
      if (
        error.graphQLErrors.find(({ message }) => message === "unauthenticated")
      ) {
        // window.alert("UNAUTHENTICATED");
        logout();
      }
    }
    if (error.networkError) {
      console.log(`[Network error]: ${error.networkError}`);
    }
  }

  const errorLink = onError(errorHandler);

  const link = authLink.concat(errorLink).concat(httpLink);

  return new ApolloClient({
    link,
    cache
  });
}

export const ApolloProvider: FC = props => {
  const { children } = props;
  const { logout } = useAuth();

  const client = clientFactory(logout);

  return <ReactApolloProvider client={client}>{children}</ReactApolloProvider>;
};
