import React, { FC } from "react";
import { AppointmentModel, AppointmentRequestModel } from "../types";
import { Modal, ModalHeader } from "components/Modal";
// import { DropdownButton } from "components/DropdownButton";
import {
  Dropdown,
  OptionsButton,
  DropdownMenu,
  DropdownMenuButton,
} from "components/Dropdown";
import { DetailList, DetailItem, VerticalField } from "@ovicare/ui";
import { displayPhoneNumber } from "../P2PRequestShowScreen";
import { QuickAppointmentDetails } from "../QuickAppointmentDetails";

interface ViewAppointmentModalProps {
  isOpen: boolean;
  onClose(): void;
  openCancelAppointment(appointment: AppointmentModel): void;
  openRescheduleAppointment(appointment: AppointmentModel): void;
  appointmentRequest?: AppointmentRequestModel;
  appointment?: AppointmentModel;
}

export const ViewAppointmentModal: FC<ViewAppointmentModalProps> = (props) => {
  const {
    isOpen,
    onClose,
    appointment,
    appointmentRequest,
    openCancelAppointment,
    openRescheduleAppointment,
  } = props;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="max-w-2xl">
      <ModalHeader icon="user-md" title="Appointment" />
      <div className="ViewAppointmentModal px-4 pb-5">
        <div className="flex p-2 -mx-2">
          <div className="__AppointmentDetails flex-1 p-4 mx-2 border border-blue-200 rounded-lg">
            <h6 className="text-sm font-bold text-gray-700">
              Appointment Details:
            </h6>
            {appointment && (
              <div className="flex justify-center">
                <QuickAppointmentDetails appointment={appointment} />
              </div>
            )}

            {appointmentRequest && (
              <>
                <h6 className="mt-6 text-sm font-bold text-gray-700">
                  P2P Contact Info:
                </h6>
                <DetailList className="mt-4">
                  <DetailItem label="Name of Provider on Case" labelWidth={210}>
                    {appointmentRequest.requestingProviderName || "-"}
                  </DetailItem>
                  <DetailItem label="Contact Person Name" labelWidth={210}>
                    {appointmentRequest.nameOfContactPerson || "-"}
                  </DetailItem>
                  <DetailItem label="Contact Person Location" labelWidth={210}>
                    {appointmentRequest.contactPersonLocation || "-"}
                  </DetailItem>
                  {appointmentRequest.contactEmail ? (
                    <DetailItem
                      label="Requesting Provider Email"
                      labelWidth={210}
                    >
                      <span className="whitespace-no-wrap">
                        {appointmentRequest.contactEmail}
                      </span>
                    </DetailItem>
                  ) : null}
                  <DetailItem label="Phone Number for P2P" labelWidth={210}>
                    <p className="whitespace-no-wrap">
                      {displayPhoneNumber(
                        appointmentRequest.contactPhoneNumber.formatted,
                        appointmentRequest.contactPhoneNumberExtension
                      )}
                    </p>
                  </DetailItem>
                  {appointmentRequest.alternateContactPhoneNumber ? (
                    <DetailItem
                      label="Alternate Contact Phone"
                      labelWidth={210}
                    >
                      {displayPhoneNumber(
                        appointmentRequest.alternateContactPhoneNumber
                          .formatted,
                        appointmentRequest.alternateContactPhoneNumberExtension
                      )}
                    </DetailItem>
                  ) : null}
                  <DetailItem label="Contact Instruction" labelWidth={210}>
                    {appointmentRequest.contactInstructions || "-"}
                  </DetailItem>
                </DetailList>
              </>
            )}
          </div>
          <div className="__AppointmentActions mx-2">
            <Dropdown>
              <OptionsButton label={"Actions"} />
              <DropdownMenu>
                <DropdownMenuButton
                  onClick={
                    appointment
                      ? () => openRescheduleAppointment(appointment)
                      : undefined
                  }
                >
                  Reschedule Appointment
                </DropdownMenuButton>
                <DropdownMenuButton
                  onClick={
                    appointment
                      ? () => openCancelAppointment(appointment)
                      : undefined
                  }
                >
                  Cancel Appointment
                </DropdownMenuButton>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        {appointment && (
          <p className="px-2 text-xs text-gray-300">
            <span className="select-none">ID </span>
            <span className="italic">{appointment.id}</span>
          </p>
        )}
        {appointment?.cancellationReason && (
          <div className="p-4">
            <VerticalField label="Cancellation Reason">
              {appointment.cancellationReason}
            </VerticalField>
          </div>
        )}
        {appointment?.missedReason && (
          <div className="p-4">
            <VerticalField label="Missed Reason">
              {appointment.missedReason}
            </VerticalField>
          </div>
        )}
      </div>
    </Modal>
  );
};
