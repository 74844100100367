import { FC } from "react";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import {
  FAIcon,
  HorizontalTextField,
  HorizontalDateMaskField,
  Button,
  HorizontalSelectField,
  InputText,
  HorizontalField,
} from "@ovicare/ui";
import { ISODateToLocal, localDateRegex } from "@ovicare/common";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { CaseLookupParams } from "./CaseInfoStep";

const HEALTH_PLANS = gql`
  query GetHealthPlans {
    healthPlans(filter: { active: true }) {
      id
      name
      externalSystemName
    }
  }
`;

interface Data {
  healthPlans: {
    id: string;
    name: string;
    externalSystemName: string;
  }[];
}

const linesOfBusiness = [
  "Acupuncture",
  "BCN Migration",
  "Cardiology",
  "Chemo Pathways",
  "Chiropractic",
  "DME Management",
  "Fertility",
  "Gastroenterology",
  "IMRT",
  "Laboratory",
  "Mammography Outreach",
  "Massage Therapy",
  "Medical Oncology",
  "MSK Joint Surgery",
  "MSK Spine Surgery",
  "MSK Pain Management",
  "MSM",
  "Occupational Therapy",
  "Outreach Program",
  "PF Oncology",
  "Physical Therapy",
  "Post-Acute Care",
  "Radiation Oncology",
  "Radiation Therapy",
  "Radiology",
  "Sleep Management",
  "Specialty Drugs",
  "Ultrasound",
];

const lineOfBusinessOptions: Option[] = linesOfBusiness.map((lob) => ({
  value: lob,
  label: lob,
}));

const blankCaseLookup: CaseLookupParams = {
  healthPlanId: "",
  lineOfBusiness: "",
  caseReferenceNumber: "",
  memberDob: "",
};

const currentYear = new Date().getFullYear();
const dobMinYear = 1900;
const dobMaxYear = currentYear + 1;

export interface FormValues {
  caseLookups: CaseLookupParams[];
}

interface CaseLookupsFormProps {
  initialValues?: FormValues;
  onSubmit(formValues: FormValues): void;
}

export const CaseLookupsForm: FC<CaseLookupsFormProps> = (props) => {
  const {
    onSubmit,
    initialValues = { caseLookups: [blankCaseLookup] },
  } = props;

  const { data, loading, error } = useQuery<Data>(HEALTH_PLANS);

  if (error) {
    return <p>Failed to load.</p>;
  }

  return (
    <div className="_CaseLookupsForm">
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          caseLookups: Yup.array().of(
            Yup.object().shape({
              healthPlanId: Yup.string().required("Required"),
              lineOfBusiness: Yup.string().required("Required"),
              caseReferenceNumber: Yup.string().required("Required"),
              memberDob: Yup.string()
                .required("Required")
                .matches(localDateRegex, {
                  message: "Invalid date",
                }),
            })
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          const caseLookups = values.caseLookups.map((caseLookup) =>
            caseLookup.dateOfService === ""
              ? { ...caseLookup, dateOfService: undefined }
              : caseLookup
          );
          return onSubmit({ caseLookups });
        }}
      >
        {({ status, values, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />

            <FieldArray
              name="caseLookups"
              render={(arrayHelpers) => (
                <div className="max-w-3xl mx-auto mt-6">
                  {values.caseLookups && values.caseLookups.length > 0 ? (
                    <div>
                      {values.caseLookups.map((cl, idx) => (
                        <div
                          key={idx}
                          className={`bg-white mb-4 p-4 rounded-lg ${values.caseLookups.length > 1
                            ? "border border-gray-200 shadow-md"
                            : ""
                            }`}
                        >
                          {values.caseLookups.length > 1 ? (
                            <div className="flex items-center justify-between mb-6">
                              <p className="text-sm text-gray-700">
                                Case #{idx + 1}
                              </p>
                              {idx > 0 ? (
                                <button
                                  type="button"
                                  className="btn btn-sm btn-red-alt"
                                  onClick={() => arrayHelpers.remove(idx)}
                                >
                                  Remove
                                </button>
                              ) : null}
                            </div>
                          ) : null}

                          <HorizontalSelectField
                            name={`caseLookups[${idx}].lineOfBusiness`}
                            label="Program"
                            options={lineOfBusinessOptions}
                          />

                          <div className="mt-3">
                            <HorizontalSelectField
                              name={`caseLookups[${idx}].healthPlanId`}
                              label="Health Plan"
                              options={
                                data?.healthPlans.map((hp) => ({
                                  value: hp.id,
                                  label: hp.name,
                                })) || []
                              }
                              isLoading={loading}
                            />
                          </div>

                          <div className="mt-3">
                            <HorizontalTextField
                              name={`caseLookups[${idx}].caseReferenceNumber`}
                              label="Case Reference Number"
                            />
                          </div>

                          <div className="mt-3">
                            <HorizontalDateMaskField
                              name={`caseLookups[${idx}].memberDob`}
                              label="Member Date of Birth"
                              minYear={dobMinYear}
                              maxYear={dobMaxYear}
                            />
                          </div>

                          {values.caseLookups[idx].dateOfService ? (
                            <div className="mt-3">
                              <HorizontalField label="Date of Service">
                                <InputText
                                  value={ISODateToLocal(
                                    values.caseLookups[idx].dateOfService!
                                  )}
                                  inputProps={{ disabled: true }}
                                />
                              </HorizontalField>
                            </div>
                          ) : null}
                        </div>
                      ))}
                      {values.caseLookups &&
                        values.caseLookups.length < 4 &&
                        !values.caseLookups.find((cl) => cl.isPortal) ? (
                        <div className="text-center">
                          <button
                            type="button"
                            className="btn btn-sm mx-auto border"
                            onClick={() => arrayHelpers.push(blankCaseLookup)}
                          >
                            <span className="mr-2">
                              <FAIcon icon="plus" />
                            </span>
                            Add Another Case
                          </button>
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <button type="button">Add</button>
                  )}
                </div>
              )}
            />

            <div className="max-w-3xl pt-5 mx-auto mt-8 border-t border-gray-200">
              <div className="flex justify-end">
                <span className="inline-flex ml-3 rounded-md shadow-sm">
                  <Button type="submit" color="gold" disabled={isSubmitting}>
                    Lookup Cases
                    <span className="ml-2">
                      <FAIcon icon="chevron-right" />
                    </span>
                  </Button>
                </span>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
