import React, { FC } from "react";
import { FAIcon } from "@ovicare/ui";

interface SelectSkillMessageProps {}

export const SelectSkillMessage: FC<SelectSkillMessageProps> = () => {
  return (
    <div className="SelectSkillMessage my-8 mx-auto text-center">
      <div className="text-5xl text-gray-400">
        <FAIcon icon="arrow-up" />
      </div>
      <div className="text-2xl max-w-sm my-6 mx-auto text-gray-600">
        <p>Select a skill above and click "Search" to search for providers</p>
      </div>
    </div>
  );
};
