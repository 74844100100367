import React, { FC } from "react";
import { useField, ErrorMessage } from "formik";
import { PreferredDays, Day } from "components/PreferredDays";
import { VerticalField, HorizontalField } from "@ovicare/ui";

/**
 * PreferredDaysInput.
 */

interface PreferredDaysInputProps {
  id?: string;
  name: string;
  days?: Day[];
  showInstructions?: boolean;
}

export const PreferredDaysInput: FC<PreferredDaysInputProps> = props => {
  const { id, name, days, showInstructions = true } = props;
  const [field, , helpers] = useField(name);
  const { value } = field;
  const { setValue } = helpers;

  return (
    <>
      <PreferredDays
        id={id || name}
        selectedDays={value}
        onChange={setValue}
        days={days}
        showInstructions={showInstructions}
      />
      <ErrorMessage component="p" name={name} className="help is-danger" />
    </>
  );
};

/**
 * PreferredDaysField.
 */

interface PreferredDaysFieldProps extends PreferredDaysInputProps {
  label: string;
  indicateOptional?: boolean;
}

export const PreferredDaysField: FC<PreferredDaysFieldProps> = props => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <PreferredDaysInput {...rest} />
    </VerticalField>
  );
};

export const HorizontalPreferredDaysField: FC<PreferredDaysFieldProps> = props => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <PreferredDaysInput {...rest} />
    </HorizontalField>
  );
};
