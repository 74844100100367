import React, { FC } from "react";
import "./BlockSpinner.css";

interface BlockSpinnerProps {
  className?: string;
}

export const BlockSpinner: FC<BlockSpinnerProps> = props => {
  const { className } = props;
  return (
    <div
      className={`BlockSpinner ${className || ""}`}
      role="progressbar"
      aria-busy={true}
      aria-label="Loading"
    />
  );
};
