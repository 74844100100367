import React, { FC, useCallback } from "react";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
// import { useToast } from "layouts/PortalLayout/Toast";
import {
  HorizontalTextAreaField,
  SingleCheckboxInput,
  HorizontalField,
  Button
} from "@ovicare/ui";
import { AppointmentModel } from "../types";

const MUTATION = gql`
  mutation CancelAppointment(
    $id: UUID4!
    $appointment: CancelAppointmentInput!
  ) {
    cancelAppointment(id: $id, appointment: $appointment) {
      errors {
        key
        message
      }
      appointment {
        id
      }
    }
  }
`;

interface MutationData {
  cancelAppointment: {
    errors?: InputError[];
    appointment?: {
      id: string;
    };
  };
}

interface MutationVariables {
  id: string;
  appointment: FormValues;
}

interface FormValues {
  cancellationReason: string;
  requiresReschedule: boolean;
}

interface CancelAppointmentFormProps {
  appointment: AppointmentModel;
  onSuccess?(): void;
  refetchQueries: any;
}

export const CancelAppointmentForm: FC<CancelAppointmentFormProps> = props => {
  const { refetchQueries, onSuccess, appointment } = props;
  const [cancelAppointment] = useMutation<MutationData, MutationVariables>(
    MUTATION
  );
  // const toast = useToast()

  const onSubmit = useCallback(
    (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });
      cancelAppointment({
        variables: { id: appointment.id, appointment: values },
        refetchQueries
      }).then(
        resp => {
          if (resp && resp.data && resp.data.cancelAppointment.errors) {
            setStatus({ errors: resp.data.cancelAppointment.errors });
          } else if (
            resp &&
            resp.data &&
            resp.data.cancelAppointment.appointment
          ) {
            // it worked
            // toast.success("Success");
            if (onSuccess) onSuccess();
          }
          setSubmitting(false);
        },
        rej => {
          setStatus({
            errors: [{ key: "", message: "Something went wrong" }]
          });
          setSubmitting(false);
        }
      );
    },
    [cancelAppointment, refetchQueries, onSuccess, appointment.id]
  );

  return (
    <div className="_CancelAppointmentForm">
      <Formik<FormValues>
        initialValues={{
          cancellationReason: "",
          requiresReschedule: false
        }}
        validationSchema={Yup.object().shape({
          cancellationReason: Yup.string()
            .trim()
            .required("Required")
        })}
        onSubmit={onSubmit}
      >
        {({ status, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />
            <div className="mt-3">
              <HorizontalTextAreaField
                name="cancellationReason"
                label="Cancellation Reason"
                autoFocus
              />
            </div>
            <div className="mt-3">
              <HorizontalField label="">
                <SingleCheckboxInput
                  name="requiresReschedule"
                  checkboxLabel="Requires Reschedule"
                />
              </HorizontalField>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                <Button
                  type="submit"
                  kind="secondary"
                  color="red"
                  disabled={isSubmitting}
                >
                  Cancel Appointment
                </Button>
              </span>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
