import React, { FC, useState, useCallback } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Tooltip } from '../Tooltip/Tooltip';
import { useTimeout } from '../../hooks/hookHelpers';

interface CopyTextProps {
  text: string;
  label?: string;
}

export const CopyText: FC<CopyTextProps> = props => {
  const { text, label } = props;
  const [copied, setCopied] = useState(false);
  const setTimeout = useTimeout();

  const clearMessage = useCallback(() => {
    setTimeout(() => setCopied(false), 150);
  }, [setTimeout, setCopied]);

  const handleCopyClick = useCallback(() => {
    setCopied(true);
    setTimeout(() => setCopied(false), 4000);
  }, [setTimeout, setCopied]);

  return (
    <Tooltip
      tip={copied ? 'Copied!' : 'Click to Copy'}
      onMouseLeave={clearMessage}
      color="purple"
      style={{ width: 110 }}
    >
      <CopyToClipboard text={text} onCopy={handleCopyClick}>
        <span className="CopyText border-b border-b-2 border-dashed border-navy-700 cursor-pointer font-mono text-navy-700">
          {label || text}
        </span>
      </CopyToClipboard>
    </Tooltip>
  );
};
