import React, { FC } from "react";
// import cx from 'classnames';
import { addDays, startOfDay, lightFormat } from "date-fns";
import { FAIcon } from "@ovicare/ui";

const today = new Date();

interface WeekNavProps {
  value: number;
  onChange(weekOffset: number): void;
}

export const WeekNav: FC<WeekNavProps> = props => {
  const { value, onChange } = props;
  const weekStart = startOfDay(addDays(today, value * 7));
  const weekEnd = addDays(weekStart, 6);

  return (
    <div className="WeekNav border-b flex items-center justify-between mt-2 px-4 py-2">
      <button
        type="button"
        className={`bg-transparent btn btn-blue-alt btn-sm ${
          value === 0
            ? "cursor-not-allowed hover:bg-transparent hover:text-gray-400 text-gray-400"
            : ""
        }`}
        disabled={value === 0}
        onClick={() => onChange(value - 1)}
      >
        <span className="mr-2">
          <FAIcon icon="arrow-left" />
        </span>
        Prev Week
      </button>

      <div>
        <p className="text-gray-700 text-sm">
          <span className="font-semibold mr-2 text-gray-800">
            {lightFormat(weekStart, "M/d/yyyy")} -{" "}
            {lightFormat(weekEnd, "M/d/yyyy")}
          </span>
          ({weekIndexText(value)})
        </p>
      </div>

      <button
        type="button"
        className="bg-transparent btn btn-blue-alt btn-sm"
        onClick={() => onChange(value + 1)}
      >
        Next Week
        <span className="ml-2">
          <FAIcon icon="arrow-right" />
        </span>
      </button>
    </div>
  );
};

function weekIndexText(week: number): string {
  if (week === 0) {
    return `Upcoming week`;
  } else if (week === -1) {
    return `Preceding week`;
  } else if (week < -1) {
    return `${week * -1} weeks ago`;
  } else if (week === 1) {
    return `1 week out`;
  } else if (week > 1) {
    return `${week} weeks out`;
  }
  // this won't ever be reached
  return "";
}
