import React, { FC } from "react";
import cx from "classnames";
import { FAIcon } from "@ovicare/ui";
import "./PreferredDays.css";

export type Day = "Mon" | "Tues" | "Wed" | "Thurs" | "Fri";

export const defaultDays: Day[] = ["Mon", "Tues", "Wed", "Thurs", "Fri"];

interface PreferredDaysProps {
  id?: string;
  days?: Day[];
  selectedDays: Day[];
  onChange?(selectedDays: Day[]): void;
  showInstructions?: boolean;
}

export const PreferredDays: FC<PreferredDaysProps> = (props) => {
  const {
    id,
    days = defaultDays,
    selectedDays,
    showInstructions = false,
    onChange,
  } = props;

  function handleChange(day: Day) {
    if (onChange) {
      const values = selectedDays.includes(day)
        ? selectedDays.filter((d: string) => d !== day)
        : selectedDays.concat(day);
      onChange(values);
    }
  }

  return (
    <div id={id} className="PreferredDays">
      <div className="PreferredDays__columns" style={{ position: "relative" }}>
        {days.map((day) => (
          <div
            className="PreferredDays__column text-center"
            key={day}
            onClick={() => handleChange(day)}
          >
            <div className="PreferredDays__cell PreferredDays__day_header">
              {day}
            </div>
            <div
              className={cx("PreferredDays__cell PreferredDays__day_body", {
                PreferredDays__selected: selectedDays.includes(day),
                PreferredDays__unselected: !selectedDays.includes(day),
              })}
            >
              <FAIcon icon={selectedDays.includes(day) ? "check" : "times"} />
            </div>
          </div>
        ))}
        {showInstructions ? (
          <div className="PreferredDays__instructions_container">
            <div className="PreferredDays__instructions">
              <span style={{ marginRight: "0.75em" }}>
                <FAIcon icon="arrow-left" />
              </span>
              Click to edit
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
