import React, { FC, ChangeEvent, useMemo } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ErrorMessage, useField } from 'formik';
import {
  HorizontalField,
  VerticalField,
} from '../../FieldStructure/FieldStructure';
import { FAIcon } from '../../FAIcon/FAIcon';
import { IconContainer } from '../IconContainer';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

const currentYear = new Date().getFullYear();
const defaultMinYear = 1900;
const defaultMaxYear = currentYear + 1;

// prettier-ignore
const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

function datePipeFactory(minYear: number, maxYear: number) {
  return createAutoCorrectedDatePipe('mm/dd/yyyy', {
    minYear,
    maxYear,
  });
}

interface InputDateMaskProps {
  value: string;
  icon?: IconProp;
  inputProps?: MaskedInputProps;
  className?: string;
  placeholder?: string;
  onChange(newValue: string): void;
  onBlur?(e: any): void;
  minYear?: number;
  maxYear?: number;
}

export const InputDateMask: FC<InputDateMaskProps> = props => {
  const {
    value,
    onChange,
    onBlur,
    icon,
    inputProps = {},
    className,
    placeholder = 'mm/dd/yyyy',
    minYear = defaultMinYear,
    maxYear = defaultMaxYear,
  } = props;

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    onChange(evt.target.value);
  };

  const pipe = useMemo(() => datePipeFactory(minYear, maxYear), [
    minYear,
    maxYear,
  ]);

  /**
   * NB: `className` can be overridden by `inputProps`.
   */

  return (
    <div className={!!icon ? 'has-icons-left' : ''}>
      <MaskedInput
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        className={`form-input w-full ${className || ''}`}
        placeholder={placeholder}
        mask={dateMask}
        pipe={pipe}
        {...inputProps}
      />
      {!!icon && (
        <IconContainer className="text-gray-500">
          <FAIcon icon={icon} />
        </IconContainer>
      )}
    </div>
  );
};

interface DateMaskInputProps {
  name: string;
  id?: string;
  placeholder?: string;
  autoFocus?: boolean;
  icon?: IconProp;
  className?: string;
  inputProps?: MaskedInputProps;
  minYear?: number;
  maxYear?: number;
}

export const DateMaskInput: FC<DateMaskInputProps> = props => {
  const {
    id,
    name,
    icon,
    autoFocus = false,
    className = '',
    inputProps = {},
  } = props;

  const [field, meta, helpers] = useField(name);
  const { value, onBlur } = field;
  const { setValue } = helpers;

  return (
    <>
      <InputDateMask
        inputProps={{
          id: id || name,
          name,
          autoFocus,
          ...inputProps,
        }}
        icon={icon}
        className={
          meta && meta.touched && meta.error
            ? `border border-red-500 ${className}`
            : className
        }
        value={value}
        onChange={setValue}
        onBlur={onBlur}
      />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-red-500 text-xs italic"
      />
    </>
  );
};

interface DateMaskFieldProps extends DateMaskInputProps {
  label: string;
  indicateOptional?: boolean;
}

export const DateMaskField: FC<DateMaskFieldProps> = props => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <DateMaskInput {...rest} />
    </VerticalField>
  );
};

export const HorizontalDateMaskField: FC<DateMaskFieldProps> = props => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <DateMaskInput {...rest} />
    </HorizontalField>
  );
};
