import React, { FC } from "react";
import { parseISO, lightFormat } from "date-fns";
import { CommentEventModel } from "../types";
import { Tooltip } from "@ovicare/ui";
// import "./CommentEvent.scss";

function asInitial(name?: string | undefined | null): string {
  return name ? name.slice(0, 1) : "-";
}

const Avatar: FC<{ initial: string }> = ({ initial }) => {
  return (
    <p className="bg-indigo-200 flex h-8 items-center justify-center leading-none rounded-lg shadow-inner text-indigo-700 text-lg w-8">
      {initial}
    </p>
  );
};

interface CommentEventProps {
  event: CommentEventModel;
}

export const CommentEvent: FC<CommentEventProps> = props => {
  const { event } = props;

  return (
    <div className="CommentEvent">
      <article className="media">
        <figure className="media-left" style={{ width: 113, margin: "0 12px" }}>
          <div className="flex justify-end">
            <Avatar
              initial={asInitial(
                event.user?.firstName || event.agent?.firstName
              )}
            />
          </div>
        </figure>
        <div className="media-content">
          <div className="CommentEvent__bubble">
            <p style={{ fontSize: "0.875rem" }}>
              {event.user ? (
                <Tooltip tip="eviCore PSU">
                  <strong>
                    {event.user?.firstName} {event.user?.lastName}
                  </strong>
                </Tooltip>
              ) : (
                <strong>
                  {event.agent?.firstName} {event.agent?.lastName}
                </strong>
              )}

              <span
                style={{
                  fontSize: "0.75rem",
                  color: "rgb(138, 138, 138)",
                  marginLeft: "0.5rem"
                }}
              >
                {lightFormat(parseISO(event.insertedAt), "M/d/yy - h:mm a")}
              </span>
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>{event.data.comment}</p>
          </div>
        </div>
      </article>
    </div>
  );
};
