import { Day as DayModel } from "components/PreferredDays";

export interface PhoneNumberModel {
  formatted: string;
  raw: string;
}

interface UserModel {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  organization: {
    id: string;
    name: string;
  };
}

interface AgentModel {
  __typename: "Agent";
  id: string;
  firstName: string;
  lastName: string;
}

export interface CaseProfileModel {
  id: string;
  caseReferenceNumber: string;
  episodeId?: string;
  memberFirstName: string;
  memberLastName: string;
  memberDob: string;
  externalSystemName: string;
  memberMembershipNumber: string;
  insurancePlanCode?: string;
  levelOfReview: {
    value: string;
    label: string;
  };
  modality: {
    id: string;
    name: string;
  };
  healthPlan: {
    id: string;
    name: string;
  };
  memberState: {
    id: string;
    name: string;
  };
}

interface TimePreferenceModel {
  id: string;
  preferredDays: DayModel[];
  preferredTimes: string[];
  timeZoneName: string;
}

export interface AppointmentRequestModel {
  id: string;
  requestingProviderName: string;
  requestingProviderCredential: string | void;
  nameOfContactPerson?: string;
  whoIsPerformingP2p: string | void;
  otherP2pContactName: string | void;
  otherP2pContactCredential: string | void;
  contactPersonLocation?: string;
  contactPhoneNumber: PhoneNumberModel;
  contactPhoneNumberExtension?: string;
  alternateContactPhoneNumber?: PhoneNumberModel;
  alternateContactPhoneNumberExtension?: string;
  contactEmail?: string;
  contactInstructions?: string;
  createdByUser?: UserModel;
  createdByAgent?: AgentModel;
  caseProfiles: CaseProfileModel[];
  timePreference: TimePreferenceModel;
  appointments: AppointmentModel[];
  appointmentRequestEvents: AppointmentRequestEventModel[];
}

export interface AppointmentModel {
  id: string;
  appointmentRequestId: string;
  startDateString: string;
  startTimeString: string;
  timeRange: {
    start: string;
    finish: string;
    duration: number;
  };
  completed: boolean;
  cancelled: boolean;
  cancellationReason?: string;
  missed: boolean;
  missedReason?: string;
  requiresReschedule: boolean;
  replacedByAppointmentId?: string;
  receivingProvider: {
    id: string;
    nameWithAppellation: string;
    firstName: string;
    lastName: string;
  };
  insertedAt: string;
}

type EventUser = {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  organization: {
    id: string;
    name: string;
  };
};

type EventAgent = {
  __typename: "Agent";
  id: string;
  firstName: string;
  lastName?: string;
  email?: string;
};

export enum EventType {
  Scheduled = "SCHEDULED",
  AppointmentCancelled = "APPOINTMENT_CANCELLED",
  AppointmentMissed = "APPOINTMENT_MISSED",
  Comment = "COMMENT",
  AppointmentCompletionToggled = "APPOINTMENT_COMPLETION_TOGGLED",
}

interface BaseAppointmentRequestEventModel {
  id: string;
  type: EventType;
  rollupId: string;
  insertedAt: string;
  user?: EventUser;
  agent?: EventAgent;
}

interface ScheduledEventModel extends BaseAppointmentRequestEventModel {
  type: EventType.Scheduled;
  data: {
    eventType: EventType.Scheduled;
    scheduledWith: string;
    appointmentTime: string;
  };
}

interface AppointmentCancelledEventModel
  extends BaseAppointmentRequestEventModel {
  type: EventType.AppointmentCancelled;
  data: {
    eventType: EventType.AppointmentCancelled;
    wasScheduledWith: string;
    cancellationReason: string;
    requiresReschedule: boolean;
    appointmentId: string;
  };
}

interface AppointmentMissedEventModel extends BaseAppointmentRequestEventModel {
  type: EventType.AppointmentMissed;
  data: {
    eventType: EventType.AppointmentMissed;
    wasScheduledWith: string;
    missedReason: string;
    requiresReschedule: boolean;
    appointmentId: string;
  };
}

export interface CommentEventModel extends BaseAppointmentRequestEventModel {
  type: EventType.Comment;
  data: {
    eventType: EventType.Comment;
    comment: string;
  };
}

interface AppointmentCompletionToggledEventModel
  extends BaseAppointmentRequestEventModel {
  type: EventType.AppointmentCompletionToggled;
  data: {
    eventType: EventType.AppointmentCompletionToggled;
    completed: boolean;
  };
}

export type AppointmentRequestEventModel =
  | ScheduledEventModel
  | AppointmentCancelledEventModel
  | AppointmentMissedEventModel
  | CommentEventModel
  | AppointmentCompletionToggledEventModel;
