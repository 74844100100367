import React, { FC } from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { AvailabilityForm } from "./AvailabilityForm";
import { ScreenTitle } from "context/ScreenTitle";
import { Transition } from "react-transition-group";
import { firstSecondThird, translateLOR } from "@ovicare/common";
import { DetailList, DetailItem, CopyText, Spinner } from "@ovicare/ui";

const QUESTIONS_SCREEN_QUERY = gql`
  query GetQuestionScreenData($appointmentRequestId: UUID4!) {
    appointmentRequest(id: $appointmentRequestId) {
      id
      caseProfiles {
        id
        memberFirstName
        memberLastName
        memberDob
        memberMembershipNumber
        caseReferenceNumber
        episodeId
        externalSystemName
        insurancePlanCode
        levelOfReview {
          value
          label
        }
        healthPlan {
          id
          name
        }
        modality {
          id
          name
        }
        memberState {
          id
          name
        }
      }
    }
  }
`;

interface Data {
  appointmentRequest: {
    id: string;
    caseProfiles: CaseProfile[];
  };
}

interface CaseProfile {
  id: string;
  memberFirstName: string;
  memberLastName: string;
  memberDob: string;
  memberMembershipNumber: string;
  caseReferenceNumber: string;
  episodeId?: string;
  externalSystemName: string;
  insurancePlanCode?: string;
  levelOfReview: {
    value: string;
    label: string;
  };
  healthPlan: {
    id: string;
    name: string;
  };
  modality: {
    id: string;
    name: string;
  };
  memberState: {
    id: string;
    name: string;
  };
}

const transitionClassNames = {
  entering: "opacity-0 scale-75 translate-y-32",
  entered: "opacity-100 scale-100 translate-x-0",
  exiting: "opacity-0 scale-75 translate-y-32",
  exited: "opacity-0 scale-75 translate-y-32",
};

interface RouteParams {
  appointmentRequestId: string;
}

interface QuestionsStepProps {}

export const QuestionsStep: FC<QuestionsStepProps> = () => {
  const match = useRouteMatch<RouteParams>();
  const { appointmentRequestId } = match.params;

  const { data, loading, error } = useQuery<Data>(QUESTIONS_SCREEN_QUERY, {
    variables: { appointmentRequestId },
  });

  return (
    <div className="QuestionsStep mt-4 mb-8">
      <ScreenTitle title={["New P2P Request", "Questions"]} />

      {loading ? (
        <div className="p-8 text-center">
          <Spinner />
        </div>
      ) : error || !(data && data.appointmentRequest) ? (
        <p>Failed to load.</p>
      ) : (
        <Transition appear in timeout={0}>
          {(state) => (
            <div
              className={`_ZoomIn transform transition-all ease-in-out duration-500 ${transitionClassNames[state]}`}
            >
              <div className="flex -mx-2 mt-4">
                <div className="flex-1 max-w-sm mx-2 p-2 bg-white rounded-xl border shadow-xl">
                  <section>
                    <h3 className="font-bold mt-4 mx-4 text-2xl text-gray-800">
                      Case Info
                    </h3>

                    <div className="mt-6">
                      {data.appointmentRequest.caseProfiles.map(
                        (caseProfile, idx) => (
                          <div
                            key={caseProfile.id}
                            className="border m-2 p-3 pb-8 rounded rounded-lg shadow-md"
                          >
                            <p className="text-lg text-gray-700">
                              {firstSecondThird(idx + 1)} Case
                            </p>
                            <DetailList className="mt-4">
                              <DetailItem label="Case #">
                                <CopyText
                                  text={caseProfile.caseReferenceNumber}
                                />
                              </DetailItem>
                              <DetailItem label="Episode ID">
                                <p>{caseProfile.episodeId}</p>
                              </DetailItem>
                              <DetailItem label="Member Name">
                                <p className="capitalize">
                                  {caseProfile.memberFirstName}{" "}
                                  {caseProfile.memberLastName}
                                </p>
                              </DetailItem>
                              <DetailItem label="Member DOB">
                                <p>{caseProfile.memberDob}</p>
                              </DetailItem>
                              <DetailItem label="Member State">
                                <p>{caseProfile.memberState.name}</p>
                              </DetailItem>
                              <DetailItem label="Health Plan">
                                <p>{caseProfile.healthPlan.name}</p>
                              </DetailItem>
                              <DetailItem label="Member ID">
                                <p>{caseProfile.memberMembershipNumber}</p>
                              </DetailItem>
                              <DetailItem label="Case Type">
                                <p>{caseProfile.modality.name}</p>
                              </DetailItem>
                              <DetailItem label="Level of Review">
                                <p>{levelOfReviewLabel(caseProfile) || "-"}</p>
                              </DetailItem>
                            </DetailList>
                          </div>
                        )
                      )}
                    </div>
                  </section>
                </div>

                <div className="flex-1 mx-2 bg-white px-2 pt-2 pb-8 rounded-xl border shadow-xl">
                  <div className="max-w-2xl px-2 mx-auto">
                    <h3 className="font-bold mt-4 text-2xl text-gray-800">
                      Questions
                    </h3>
                    <p className="text-gray-600 text-sm">
                      Please indicate your availability
                    </p>
                    <div className="mt-6">
                      <AvailabilityForm
                        appointmentRequestId={appointmentRequestId as string}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Transition>
      )}
    </div>
  );
};

function levelOfReviewLabel(caseProfile: CaseProfile): string | undefined {
  return translateLOR(
    caseProfile.levelOfReview.label,
    caseProfile.insurancePlanCode,
    caseProfile.memberState.name
  );
}
