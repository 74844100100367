import { FC } from "react";
import cx from "classnames";
import {
  PencilAlt_md as PencilAlt,
  ClipboardList_md as ClipboardList,
  Calendar_md as Calendar,
  CheckCircle_md as CheckCircle,
  Check_md as Check
} from "components/Heroicons";

/**
 * Icon
 */
type IconModel =
  | "check"
  | "pencil-alt"
  | "clipboard-list"
  | "calendar"
  | "check-circle";

export interface IconProps {
  icon: IconModel;
}

const Icon: FC<IconProps> = props => {
  const size = 24;
  switch (props.icon) {
    case "check":
      return <Check width={size} height={size} />;
    case "calendar":
      return <Calendar width={size} height={size} />;
    case "check-circle":
      return <CheckCircle width={size} height={size} />;
    case "clipboard-list":
      return <ClipboardList width={size} height={size} />;
    case "pencil-alt":
      return <PencilAlt width={size} height={size} />;
  }
};

/**
 * NavigationSteps.
 */

export type NavigationStepModel = {
  label: string;
  icon: IconModel;
};

interface NavigationStepsProps {
  currentStep: number;
  steps: NavigationStepModel[];
}

export const NavigationSteps: FC<NavigationStepsProps> = props => {
  const { currentStep, steps } = props;

  function getStepState(stepIndex: number) {
    if (currentStep === stepIndex) return "selected";
    if (stepIndex < currentStep) return "active";
    return "inactive";
  }

  return (
    <div className="navigationSteps flex items-center pb-8">
      {steps.map((step, idx) => (
        <NavigationStep
          key={step.label}
          state={getStepState(idx)}
          step={step}
          showSeparator={idx < steps.length - 1}
        />
      ))}
    </div>
  );
};

/**
 * NavigationStepBackground.
 */

export type NavigationStepState = "inactive" | "active" | "selected";

interface NavigationStepBackgroundProps {
  state: NavigationStepState;
}

const NavigationStepBackground: FC<NavigationStepBackgroundProps> = props => {
  const { state, children } = props;

  return (
    <div
      className={cx(
        `navigationStepBackground ${state} h-10 w-10 flex items-center justify-center rounded-full border transition-colors ease-in-out duration-300`,
        {
          // "shadow-inner": state === "active",
          "border-transparent": state !== "active",
          "bg-green-100 text-green-500 shadow-inner border-green-300":
            state === "active",
          "bg-gold-600 text-white": state === "selected",
          // "bg-transparent text-gray-400": state === "inactive"
          "bg-gray-100 text-gray-400": state === "inactive"
        }
      )}
    >
      {children}
    </div>
  );
};

/**
 * NavigationStepLine.
 */

const NavigationStepLine: FC = () => {
  return (
    <div className="navigationStepLine lg:mx-4 flex-1 mx-1 border-t-2 border-gray-400" />
  );
};

/**
 * NavigationStepLabel.
 */

const NavigationStepLabel: FC = ({ children }) => {
  return (
    <div
      className="absolute mt-1 text-xs font-semibold whitespace-no-wrap"
      style={{
        transform: "translateX(-50%)",
        marginLeft: "50%"
      }}
    >
      {children}
    </div>
  );
};

/**
 * Individual NavigationStep.
 */

interface NavigationStepProps {
  step: NavigationStepModel;
  state: NavigationStepState;
  showSeparator: boolean;
}

const NavigationStep: FC<NavigationStepProps> = props => {
  const { step, state, showSeparator } = props;

  return (
    <>
      <div
        className={cx(
          `navigationStep ${state} flex-0 h-10 w-10 relative rounded-full`,
          {
            "shadow-lg": state === "selected",
            "text-gray-800 cursor-pointer":
              state === "active" || state === "selected",
            "text-gray-500": state === "inactive"
          }
        )}
      >
        <NavigationStepBackground state={state}>
          {state === "active" ? (
            <Icon icon="check" />
          ) : (
            <Icon icon={props.step.icon} />
          )}
          {/* <svg
            width="15"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.33333 0H13.3333C14.07 0 14.6667 0.597333 14.6667 1.33333V14.6667C14.6667 15.4027 14.07 16 13.3333 16H1.33333C0.596667 16 0 15.4027 0 14.6667V1.33333C0 0.597333 0.596667 0 1.33333 0ZM3.33335 12.6667H6.66669C7.03335 12.6667 7.33335 12.3667 7.33335 12C7.33335 11.6333 7.03335 11.3333 6.66669 11.3333H3.33335C2.96669 11.3333 2.66669 11.6333 2.66669 12C2.66669 12.3667 2.96669 12.6667 3.33335 12.6667ZM11.3334 8.66667H3.33335C2.96669 8.66667 2.66669 8.36667 2.66669 8C2.66669 7.63333 2.96669 7.33333 3.33335 7.33333H11.3334C11.7 7.33333 12 7.63333 12 8C12 8.36667 11.7 8.66667 11.3334 8.66667ZM3.33335 4.66667H11.3334C11.7 4.66667 12 4.36667 12 4C12 3.63333 11.7 3.33333 11.3334 3.33333H3.33335C2.96669 3.33333 2.66669 3.63333 2.66669 4C2.66669 4.36667 2.96669 4.66667 3.33335 4.66667Z"
              fill="currentColor"
            />
          </svg> */}
        </NavigationStepBackground>
        <NavigationStepLabel>{step.label}</NavigationStepLabel>
      </div>
      {showSeparator && <NavigationStepLine />}
    </>
  );
};
