import React, { FC, HTMLAttributes, ReactNode } from 'react';
import { useField, ErrorMessage } from 'formik';
import { VerticalField } from '../../FieldStructure/FieldStructure';

interface SingleCheckboxInputProps {
  id?: string;
  name: string;
  checkboxLabel?: ReactNode;
  inputProps?: HTMLAttributes<HTMLInputElement>;
  disabled?: boolean;
}

export const SingleCheckboxInput: FC<SingleCheckboxInputProps> = props => {
  const {
    id,
    name,
    disabled = false,
    inputProps = {},
    checkboxLabel = null,
  } = props;

  const [field, , helpers] = useField(name);
  const { value } = field;
  const { setValue } = helpers;

  function handleChange() {
    setValue(!value);
  }

  return (
    <>
      <div>
        <div className="flex items-start text-sm">
          <div className="flex items-center">
            &#8203;
            <input
              id={id || name}
              name={name}
              type="checkbox"
              checked={value}
              onChange={handleChange}
              disabled={disabled}
              className="form-checkbox border-gray-400 h-4 w-4 text-indigo-600"
              {...inputProps}
            />
          </div>
          {checkboxLabel && (
            <label
              htmlFor={id || name}
              className="ml-3 font-medium text-gray-800"
            >
              {checkboxLabel}
            </label>
          )}
        </div>
        <ErrorMessage
          component="p"
          name={name}
          className="mt-2 text-red-500 text-xs italic"
        />
      </div>
    </>
  );
};

interface SingleCheckboxFieldProps extends SingleCheckboxInputProps {
  label: string;
  indicateOptional?: boolean;
}

export const SingleCheckboxField: FC<SingleCheckboxFieldProps> = props => {
  const { label, indicateOptional, ...rest } = props;
  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <SingleCheckboxInput {...rest} />
    </VerticalField>
  );
};
