import React, { FC, useCallback, useState } from "react";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { FAIcon, HorizontalTextAreaField } from "@ovicare/ui";
import { AppointmentModel } from "../types";
import { ProviderSlotSelectionModel } from "screens/NewP2PRequestScreen/ResultsStep/ProviderResults/types";
import { SlotTakenNotice } from "screens/NewP2PRequestScreen/ConfirmationStep/SlotTakenNotice";
import { isScheduleConflict } from "screens/NewP2PRequestScreen/ConfirmationStep/ConfirmationForm";
import { parseISO, lightFormat } from "date-fns";
// import { useToast } from "layouts/PortalLayout/Toast";

const MUTATION = gql`
  mutation CancelAndRescheduleAppointment(
    $id: UUID4!
    $cancelledAppointment: CancelAppointmentInput!
    $newAppointment: AppointmentInput!
  ) {
    cancelAndRescheduleAppointment(
      id: $id
      cancelledAppointment: $cancelledAppointment
      newAppointment: $newAppointment
    ) {
      errors {
        key
        message
      }
      appointment {
        id
      }
    }
  }
`;

interface MutationData {
  cancelAndRescheduleAppointment: {
    errors?: InputError[];
    appointment?: {
      id: string;
    };
  };
}

interface MutationVariables {
  id: string;
  cancelledAppointment: FormValues;
  newAppointment: {
    receivingProviderId: string;
    timeRange: {
      start: string;
      finish: string;
    };
  };
}

interface FormValues {
  cancellationReason: string;
}

interface RescheduleFormProps {
  appointment: AppointmentModel;
  selectedSlot: ProviderSlotSelectionModel;
  onSuccess(): void;
  refetchQueries: any;
  unselectSlot(): void;
}

export const RescheduleForm: FC<RescheduleFormProps> = props => {
  const {
    refetchQueries,
    onSuccess,
    appointment,
    selectedSlot,
    unselectSlot
  } = props;

  const [slotTaken, setSlotTaken] = useState(false);

  const [cancelAndRescheduleAppointment] = useMutation<
    MutationData,
    MutationVariables
  >(MUTATION);
  // const toast = useToast()

  const onSubmit = useCallback(
    (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });
      cancelAndRescheduleAppointment({
        variables: {
          id: appointment.id,
          cancelledAppointment: values,
          newAppointment: {
            receivingProviderId: selectedSlot.provider.id,
            timeRange: {
              start: selectedSlot.slot.timeRange.start,
              finish: selectedSlot.slot.timeRange.finish
            }
          }
        },
        refetchQueries
      }).then(
        resp => {
          if (resp?.data?.cancelAndRescheduleAppointment.errors) {
            const { errors } = resp.data.cancelAndRescheduleAppointment;
            if (isScheduleConflict(errors)) {
              setSlotTaken(true);
            }
            setStatus({ errors });
          } else if (resp?.data?.cancelAndRescheduleAppointment.appointment) {
            // it worked
            // toast.success("Success");
            return onSuccess();
            // closeModal();
          }
          setSubmitting(false);
        },
        rej => {
          setStatus({
            errors: [{ key: "", message: "Something went wrong" }]
          });
          setSubmitting(false);
        }
      );
    },
    [
      cancelAndRescheduleAppointment,
      refetchQueries,
      onSuccess,
      appointment,
      selectedSlot,
      setSlotTaken
    ]
  );

  return (
    <div className="RescheduleForm">
      {slotTaken ? (
        <div className="max-w-xl mx-auto my-8">
          <SlotTakenNotice onAcknowledge={unselectSlot} />
        </div>
      ) : (
        <>
          <div className="flex px-4 -mx-4 justify-center">
            <div className="mx-4">
              <h6 className="font-bold text-gray-700 text-sm">
                Old Appointment:
              </h6>
              <div className="p-4">
                <p>
                  <span className="inline-block mr-3 text-gray-600">
                    <FAIcon icon={["far", "calendar-alt"]} />
                  </span>
                  {appointment?.startDateString}
                </p>
                <p>
                  <span className="inline-block mr-3 text-gray-600">
                    <FAIcon icon={["far", "clock"]} />
                  </span>
                  {appointment?.startTimeString}
                </p>
                <p>
                  <span className="inline-block mr-3 text-gray-600">
                    <FAIcon icon="user-md" />
                  </span>
                  {appointment?.receivingProvider.nameWithAppellation}
                </p>
              </div>
            </div>
            <div className="mx-2">
              <h6 className="font-bold text-gray-700 text-sm">
                New Appointment:
              </h6>
              <div className="p-4">
                <p>
                  <span className="inline-block mr-3 text-gray-600">
                    <FAIcon icon={["far", "calendar-alt"]} />
                  </span>
                  {lightFormat(
                    parseISO(selectedSlot.slot.timeRange.start),
                    "M/d/yy"
                  )}
                </p>
                <p>
                  <span className="inline-block mr-3 text-gray-600">
                    <FAIcon icon={["far", "clock"]} />
                  </span>
                  {selectedSlot.slot.timeRange.startTimeString}
                </p>
                <p>
                  <span className="inline-block mr-3 text-gray-600">
                    <FAIcon icon="user-md" />
                  </span>
                  {selectedSlot.provider.nameWithAppellation}
                </p>
              </div>
            </div>
          </div>
          <Formik<FormValues>
            initialValues={{
              cancellationReason: ""
            }}
            validationSchema={Yup.object().shape({
              cancellationReason: Yup.string()
                .trim()
                .required("Required")
            })}
            onSubmit={onSubmit}
          >
            {({ status, isSubmitting, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormStatusErrors status={status} />
                <div className="mt-3">
                  <HorizontalTextAreaField
                    name="cancellationReason"
                    label="Reschedule Reason"
                    autoFocus
                  />
                </div>
                <div className="flex items-center justify-end mt-3 py-4">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-blue"
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};
