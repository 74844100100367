import React, {
  FC,
  InputHTMLAttributes,
  ChangeEvent,
  HTMLAttributes,
} from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useField, ErrorMessage } from 'formik';
import {
  VerticalField,
  HorizontalField,
} from '../../FieldStructure/FieldStructure';
import { Spinner } from '../../Spinner/Spinner';
import { IconContainer } from '../IconContainer';
import { SpinnerContainer } from '../SpinnerContainer';
import { FAIcon } from '../../FAIcon/FAIcon';

interface InputTextProps {
  value?: string;
  icon?: IconProp;
  isLoading?: boolean;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  className?: string;
  onChange?(newValue: string): void;
  onBlur?(e: any): void;
  onFocus?(e: any): void;
}

export const InputText: FC<InputTextProps> = props => {
  const {
    value,
    onChange,
    onBlur,
    onFocus,
    icon,
    isLoading = false,
    inputProps = {},
    className = '',
  } = props;

  function handleChange(evt: ChangeEvent<HTMLInputElement>) {
    if (onChange) onChange(evt.target.value);
  }

  /**
   * NB: `type` and `className` can both be overridden by `inputProps`.
   */

  return (
    <div className={!!icon ? 'has-icons-left' : ''}>
      <input
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
        className={`form-input w-full ${className}`}
        {...inputProps}
      />
      {icon && (
        <IconContainer className="text-gray-500">
          <FAIcon icon={icon} />
        </IconContainer>
      )}
      {isLoading && (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
    </div>
  );
};

/**
 * TextInput.
 */

interface TextInputProps {
  name: string;
  id?: string;
  placeholder?: string;
  autoFocus?: boolean;
  icon?: IconProp;
  type?: string;
  className?: string;
  inputProps?: HTMLAttributes<HTMLInputElement>;
}

export const TextInput: FC<TextInputProps> = props => {
  const {
    id,
    name,
    placeholder,
    icon,
    type = 'text',
    autoFocus = false,
    className = '',
    inputProps = {},
  } = props;

  const [field, meta, helpers] = useField(name);
  const { value, onBlur } = field;
  const { setValue } = helpers;

  return (
    <>
      <InputText
        inputProps={{
          id: id || name,
          type,
          placeholder,
          name,
          autoFocus,
          ...inputProps,
        }}
        icon={icon}
        className={
          meta && meta.touched && meta.error
            ? `${className} border border-red-500`
            : className
        }
        value={value}
        onChange={setValue}
        onBlur={onBlur}
      />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-xs italic text-red-500"
      />
    </>
  );
};

/**
 * TextField.
 */

interface TextFieldProps extends TextInputProps {
  label: string;
  indicateOptional?: boolean;
}

export const TextField: FC<TextFieldProps> = props => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <TextInput {...rest} placeholder={rest.placeholder || label} />
    </VerticalField>
  );
};

export const HorizontalTextField: FC<TextFieldProps> = props => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <TextInput {...rest} placeholder={rest.placeholder || label} />
    </HorizontalField>
  );
};
