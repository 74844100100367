import React, { FC, useState } from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { useTrail, animated } from "react-spring";
import { FilterPanel } from "./FilterPanel";
import { WeekNav } from "./WeekNav";
import { SelectSkillMessage } from "./SelectSkillMessage";
import { ProviderResult } from "./ProviderResult";
import { BlockSpinner } from "components/BlockSpinner";
import { FAIcon } from "@ovicare/ui";
import {
  ProviderResultModel,
  ProviderResultsFilterModel as FilterModel,
  ProviderSlotSelectionModel,
} from "./types";

/**
 * Loading.
 */
const Loading: FC = () => {
  return (
    <div className="flex justify-center mt-16">
      <BlockSpinner />
    </div>
  );
};

const PROVIDER_AVAILABILITIES_QUERY = gql`
  query GetProviderAvailabilities(
    $appointmentRequestId: UUID4!
    $timeWindow: TimeRangeInput
    $searchWeekOffset: Int
    $timePreference: TimePreferenceInput
    $filter: AvailableProviderSlotsFilter
    $slotLength: Int
    $slotBuffer: Int
  ) {
    providerAvailabilities(
      appointmentRequestId: $appointmentRequestId
      timeWindow: $timeWindow
      searchWeekOffset: $searchWeekOffset
      timePreference: $timePreference
      filter: $filter
      slotLength: $slotLength
      slotBuffer: $slotBuffer
    ) {
      cursor
      endOfList
      items {
        provider {
          id
          nameWithAppellation
          firstName
          lastName
          primarySpecialty
          priorityBySkill
          skills {
            id
            name
          }
          specialties {
            id
            name
          }
        }
        dates {
          date
          slots {
            timeRange {
              start
              startTimeString(format: "{h12}:{m} {am} {Zabbr}")
              finish
              finishTimeString(format: "{h12}:{m} {am} {Zabbr}")
            }
          }
        }
      }
    }
  }
`;

interface Data {
  providerAvailabilities: Paginated<ProviderResultModel>;
}

interface ProviderResultsProps {
  appointmentRequestId: string;
  onSlotClick(selectedSlot: ProviderSlotSelectionModel): void;
}

export const ProviderResults: FC<ProviderResultsProps> = (props) => {
  const { appointmentRequestId, onSlotClick } = props;
  const [filter, setFilter] = useState<FilterModel>({ skillIds: [] });
  const [searchWeekOffset, setSearchWeekOffset] = useState(0);

  const { loading, data, error } = useQuery<Data>(
    PROVIDER_AVAILABILITIES_QUERY,
    {
      variables: { appointmentRequestId, filter, searchWeekOffset },
      fetchPolicy: "network-only",
    }
  );

  const results = data?.providerAvailabilities.items || [];
  const trail = useTrail(results.length, {
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <div className="_ProviderResults">
      <FilterPanel
        appointmentRequestId={appointmentRequestId}
        value={filter}
        onChange={setFilter}
      />

      <div className="p-4">
        <p className="text-sm italic text-center text-gray-700">
          The list of physicians returned are all trained and prepared to have a
          Peer to Peer discussion for this case.
        </p>
      </div>

      <WeekNav value={searchWeekOffset} onChange={setSearchWeekOffset} />

      {filter.skillIds && filter.skillIds.length === 0 ? (
        <SelectSkillMessage />
      ) : loading ? (
        <Loading />
      ) : error || !results ? (
        <p>Failed to load</p>
      ) : results.length === 0 ? (
        <div className="flex flex-col items-center mt-12">
          <div className="text-4xl text-gray-400">
            <FAIcon icon="exclamation" />
          </div>
          <p className="max-w-2xl mt-6 mb-8 text-2xl text-center text-gray-700">
            This case requires special handling. Please contact our Physician
            Support Unit at 1-800-792-8744, option 1 to schedule a Peer to Peer.
          </p>
        </div>
      ) : (
        <div className="mt-4">
          {trail.map((props, index) => (
            <animated.div key={results[index].provider.id} style={props}>
              <ProviderResult
                providerResult={results[index]}
                onSlotClick={onSlotClick}
              />
            </animated.div>
          ))}
        </div>
      )}
    </div>
  );
};
