import getDay from 'date-fns/getDay';

const shortDayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const longDayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export function ddd(date: Date): string {
  return shortDayNames[getDay(date)];
}

export function dddd(date: Date): string {
  return longDayNames[getDay(date)];
}
