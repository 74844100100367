import React, { FC } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { PortalLayout } from "layouts/PortalLayout";
import { NewP2PRequestScreen } from "screens/NewP2PRequestScreen";
import { MyP2PRequestsScreen } from "screens/MyP2PRequestsScreen";
import { P2PRequestShowScreen } from "screens/P2PRequestShowScreen";

export const AuthenticatedApp: FC = () => {
  const match = useRouteMatch();

  return (
    <div className="_AuthenticatedApp">
      <PortalLayout>
        <Switch>
          <Route path={`${match.path}/p2p_request_wizard`}>
            <NewP2PRequestScreen />
          </Route>
          <Route exact path={`${match.path}/requests`}>
            <MyP2PRequestsScreen />
          </Route>
          <Route path={`${match.path}/requests/:appointmentRequestId`}>
            <P2PRequestShowScreen />
          </Route>
        </Switch>
      </PortalLayout>
    </div>
  );
};
