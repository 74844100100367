import { FC, useEffect } from "react";
import qs from "query-string";
import { Redirect, useLocation } from "react-router-dom";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import { useAuth } from "context/AuthContext";

/**
 * This is used after an IdP has sent us a SAML assertion.
 * We'll redirect the user's browser to this page, which will
 * POST to our API, where there's a valid SAML assertion in
 * the session, and this Mutation will return a JWT for our
 * client-side SPA to use to authenticate the user.
 *
 * After retrieving the JWT from the server, we'll finally
 * redirect the user to the SPA page they were originally
 * trying to access (or the /dashboard) if there isn't one.
 */
const ISSUE_JWT_FOR_SAML_AUTHD_TOKEN = gql`
  mutation IssueJwtForSAMLAuthdToken($id: UUID4!, $token: String!) {
    issueJwtForSamlAuthenticatedToken(id: $id, token: $token) {
      errors {
        key
        message
      }
      session {
        token
      }
    }
  }
`;

interface MutationData {
  issueJwtForSamlAuthenticatedToken: {
    errors?: InputError[];
    session?: {
      token: string;
    };
  };
}

export const SAMLRedirectScreen: FC = () => {
  const location = useLocation();
  const params = qs.parse(location.search);
  const {
    id: agentId,
    token,
    return_to: returnToPath = "/cw/p2p_request_wizard/new",
  } = params;

  const { login, logout } = useAuth();

  const [fetchJwtForSaml, { data, loading, error }] = useMutation<MutationData>(
    ISSUE_JWT_FOR_SAML_AUTHD_TOKEN
  );

  useEffect(() => {
    logout();
    fetchJwtForSaml({
      variables: {
        id: agentId,
        token,
      },
    }).then(
      (resp) => {
        if (resp?.data?.issueJwtForSamlAuthenticatedToken?.session) {
          const { token } = resp.data.issueJwtForSamlAuthenticatedToken.session;
          login(token);
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="_SAMLRedirectScreen bg-gray-50 sm:px-6 lg:px-8 flex flex-col justify-center min-h-screen py-12">
      <div className="sm:mx-auto sm:w-full sm:max-w-6xl opacity-25">
        <div>
          <p className="mt-3 mb-1 font-semibold">Return Path:</p>
          <pre className="p-2 overflow-x-scroll text-xs bg-gray-200 rounded">
            {returnToPath}
          </pre>
        </div>
        <div>
          <p className="mt-3 mb-1 font-semibold">location.search:</p>
          <pre className="p-2 overflow-x-scroll text-xs bg-gray-200 rounded">
            {location.search}
          </pre>
        </div>
        <div>
          <p className="mt-3 mb-1 font-semibold">params:</p>
          <pre className="p-2 overflow-x-scroll text-xs bg-gray-200 rounded">
            {JSON.stringify(params, null, 2)}
          </pre>
        </div>
        <div>
          {loading || (!data && !error) ? (
            <p>Loading...</p>
          ) : error ? (
            <div>
              <p className="mt-3 mb-1 font-semibold">Error:</p>
              <pre className="p-2 overflow-x-scroll text-xs bg-gray-200 rounded">
                {JSON.stringify(error, null, 2)}
              </pre>
            </div>
          ) : data && data.issueJwtForSamlAuthenticatedToken ? (
            <div>
              <p className="mt-3 mb-1 font-semibold">Success:</p>
              <Redirect to={returnToPath as string} />
              <pre className="p-2 overflow-x-scroll text-xs bg-gray-200 rounded">
                {JSON.stringify(
                  data.issueJwtForSamlAuthenticatedToken,
                  null,
                  2
                )}
              </pre>
            </div>
          ) : (
            <p>
              {/* This should never happen. "Error Code 91" is arbitrary, but grep-able */}
              Something went wrong. Error Code 91.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
