import React, { FC } from "react";
import { parseISO, lightFormat } from "date-fns";
import { useToggle, CircleIcon } from "@ovicare/ui";
import { firstSecondThird, ddd } from "@ovicare/common";
import { ProviderResultModel, ProviderSlotSelectionModel } from "./types";

/**
 * ProviderResult.
 */
interface ProviderResultProps {
  providerResult: ProviderResultModel;
  onSlotClick(providerSlotSelection: ProviderSlotSelectionModel): void;
}

export const ProviderResult: FC<ProviderResultProps> = (props) => {
  const { providerResult, onSlotClick } = props;
  const [showAll, toggleShowAll] = useToggle(false);

  return (
    <div className="_ProviderResult mb-4 bg-white border rounded-xl shadow-xl">
      {/* Header */}
      <div className="_ProviderResult__header flex items-center justify-between px-4 py-2">
        <div className="flex-1 flex items-center">
          <CircleIcon icon="user-md" size={32} iconSize={16} />
          <div className="ml-4">
            <p className="font-bold text-gray-700">
              {providerResult.provider.nameWithAppellation}
            </p>
          </div>
        </div>

        {providerResult.provider.priorityBySkill ? (
          <div className="flex-0">
            <p className="font-semibold mx-2 text-indigo-500 text-sm">
              {firstSecondThird(providerResult.provider.priorityBySkill)}{" "}
              Priority by Skill
            </p>
          </div>
        ) : null}
      </div>

      {/* Dates */}
      <div className="flex pt-2 py-4">
        {providerResult.dates.map((day) => (
          <div
            key={`${providerResult.provider.id}--${day.date}`}
            className="flex flex-1 flex-col items-center px-2"
          >
            <h3 className="text-sm text-gray-700">
              <span className="font-bold">{ddd(parseISO(day.date))}</span>{" "}
              {lightFormat(parseISO(day.date), "M/d/yy")}
            </h3>
            {!day.slots || day.slots.length === 0 ? (
              <p className="mt-4 text-gray-600 text-xl">-</p>
            ) : (
              <>
                <ul className="mt-3">
                  {(!showAll ? day.slots.slice(0, 4) : day.slots).map(
                    (timeSlot) => (
                      <li key={timeSlot.timeRange.startTimeString}>
                        <button
                          type="button"
                          className="mx-auto mb-1 bg-blue-100 block border-2 border-transparent font-semibold hover:bg-blue-200 hover:border-blue-700 leading-loose rounded-lg text-blue-800 text-center text-sm transition-colors w-28 ease-in-out duration-150"
                          onClick={() =>
                            onSlotClick({
                              provider: providerResult.provider,
                              slot: timeSlot,
                            })
                          }
                        >
                          {timeSlot.timeRange.startTimeString}
                        </button>
                      </li>
                    )
                  )}
                </ul>
                {!showAll && day.slots && day.slots.length > 4 ? (
                  <button
                    type="button"
                    className="font-semibold hover:underline mt-1 text-blue-600 text-sm"
                    onClick={toggleShowAll}
                  >
                    Show more...
                  </button>
                ) : null}
              </>
            )}
          </div>
        ))}
      </div>
      <div className="p-2 text-center">
        {showAll ? (
          <button
            type="button"
            className="border btn btn-gray-alt btn-sm duration-150 ease-in-out hover:bg-blue-50 max-w-md px-2 py-1 rounded-lg transition-colors w-full"
            onClick={toggleShowAll}
          >
            Show less...
          </button>
        ) : null}
      </div>
    </div>
  );
};
