import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import ReactModal from "react-modal";
import "typeface-inter";
import "./styles/tailwind.dist.css";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import createIconLibrary from "./createIconLibrary";
import { AppProviders } from "context";

createIconLibrary();

ReactModal.setAppElement("#root");

ReactDOM.render(
  <AppProviders>
    <App />
  </AppProviders>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
