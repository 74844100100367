import { FC, useState, useCallback } from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { ScreenTitle } from "context/ScreenTitle";
import {
  Spinner,
  CircleIcon,
  DetailList,
  DetailItem,
  CopyText,
  FAIcon,
} from "@ovicare/ui";
import {
  AppointmentRequestModel,
  AppointmentModel,
  CaseProfileModel,
} from "./types";
import { AppointmentRequestEvent } from "./AppointmentRequestEvent";
import { firstSecondThird, translateLOR } from "@ovicare/common";
import {
  appointmentIsScheduled,
  appointmentShouldBeFinished,
  sortByNewest,
  appointmentStatus,
} from "screens/MyP2PRequestsScreen/helpers";
import { AppointmentStatus } from "screens/MyP2PRequestsScreen/types";
import { ViewAppointmentModal } from "./ViewAppointmentModal";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { CancelAppointmentModal } from "./CancelAppointmentModal";
import { RescheduleAppointmentModal } from "./RescheduleAppointmentModal";

export const APPOINTMENT_REQUEST_SHOW_QUERY = gql`
  query GetAppointmentRequest($id: UUID4!) {
    appointmentRequest(id: $id) {
      id
      requestingProviderName
      requestingProviderCredential
      nameOfContactPerson
      contactPersonLocation
      whoIsPerformingP2p
      otherP2pContactName
      otherP2pContactCredential
      contactPhoneNumber {
        formatted
        raw
      }
      contactPhoneNumberExtension
      alternateContactPhoneNumber {
        formatted
        raw
      }
      alternateContactPhoneNumberExtension
      contactEmail
      contactInstructions
      insertedAt
      createdByAgent {
        id
        firstName
        lastName
        email
        npi
      }
      createdByUser {
        id
        firstName
        lastName
      }
      caseProfiles {
        id
        caseReferenceNumber
        episodeId
        memberFirstName
        memberLastName
        memberDob
        memberMembershipNumber
        externalSystemName
        insurancePlanCode
        levelOfReview {
          value
          label
        }
        modality {
          id
          name
        }
        healthPlan {
          id
          name
        }
        memberState {
          id
          name
        }
      }
      timePreference {
        id
        preferredDays
        preferredTimes
        timeZoneName
      }
      appointments {
        id
        appointmentRequestId
        startDateString: startTimeString(format: "{WDshort} {M}/{D}/{YY}")
        startTimeString(format: "{h12}:{m} {am} {Zabbr}")
        timeRange {
          start
          finish
          duration
        }
        completed
        cancelled
        cancellationReason
        missed
        missedReason
        requiresReschedule
        requiresReschedule
        replacedByAppointmentId
        receivingProvider {
          id
          nameWithAppellation
          firstName
          lastName
        }
        insertedAt
      }
      appointmentRequestEvents {
        id
        data {
          __typename
          ... on AppointmentRequestScheduledEventData {
            eventType
            appointmentTime
            scheduledWith
          }
          ... on AppointmentRequestAppointmentCancelledEventData {
            eventType
            wasScheduledWith
            cancellationReason
            requiresReschedule
            appointmentId
          }
          ... on AppointmentRequestAppointmentMissedEventData {
            eventType
            wasScheduledWith
            missedReason
            requiresReschedule
            appointmentId
          }
          ... on AppointmentRequestCommentEventData {
            eventType
            comment
          }
          ... on AppointmentRequestAppointmentCompletionToggledEventData {
            eventType
            completed
          }
        }
        insertedAt
        rollupId
        agent {
          id
          firstName
          lastName
          email
          npi
        }
        user {
          id
          firstName
          lastName
        }
        type
      }
    }
  }
`;

interface Data {
  appointmentRequest: AppointmentRequestModel;
}

/**
 * P2PRequestPanel.
 */
interface P2PRequestPanelProps {
  appointmentRequest: AppointmentRequestModel;
}

const P2PRequestPanel: FC<P2PRequestPanelProps> = (props) => {
  const { appointmentRequest } = props;

  return (
    <section className="p-4 bg-white border rounded-lg shadow-xl">
      <div className="flex items-center">
        <CircleIcon icon={["far", "address-card"]} />
        <h3 className="ml-3 text-xl text-gray-700">P2P Request</h3>
      </div>
      <div className="mt-4">
        {/* Contact Details */}
        <h4 className="text-lg font-semibold text-gray-800">Contact Details</h4>
        <DetailList className="mt-4">
          <DetailItem label="Name of Provider on Case" labelWidth={210}>
            {appointmentRequest.requestingProviderName || "-"}
          </DetailItem>
          <DetailItem label="Contact Person Name" labelWidth={210}>
            {appointmentRequest.nameOfContactPerson || "-"}
          </DetailItem>
          <DetailItem label="Contact Person Location" labelWidth={210}>
            {appointmentRequest.contactPersonLocation || "-"}
          </DetailItem>
          {appointmentRequest.contactEmail ? (
            <DetailItem label="Requesting Provider Email" labelWidth={210}>
              <span className="whitespace-no-wrap">
                {appointmentRequest.contactEmail}
              </span>
            </DetailItem>
          ) : null}
          <DetailItem label="Phone Number for P2P" labelWidth={210}>
            <p className="whitespace-no-wrap">
              {displayPhoneNumber(
                appointmentRequest.contactPhoneNumber.formatted,
                appointmentRequest.contactPhoneNumberExtension
              )}
            </p>
          </DetailItem>
          {appointmentRequest.alternateContactPhoneNumber ? (
            <DetailItem label="Alternate Contact Phone" labelWidth={210}>
              {displayPhoneNumber(
                appointmentRequest.alternateContactPhoneNumber.formatted,
                appointmentRequest.alternateContactPhoneNumberExtension
              )}
            </DetailItem>
          ) : null}
          <DetailItem label="Contact Instruction" labelWidth={210}>
            {appointmentRequest.contactInstructions || "-"}
          </DetailItem>
        </DetailList>
      </div>

      {/* Contact Details */}
      <div className="mt-4">
        <h4 className="text-lg font-semibold text-gray-800">Cases</h4>
        {appointmentRequest.caseProfiles.map((caseProfile, idx) => (
          <div
            key={caseProfile.id}
            className="p-3 pb-8 m-2 border rounded rounded-lg shadow-md"
          >
            <p className="text-lg text-gray-700">
              {firstSecondThird(idx + 1)} Case
            </p>
            <DetailList className="mt-4">
              <DetailItem label="Case #">
                <CopyText text={caseProfile.caseReferenceNumber} />
              </DetailItem>
              <DetailItem label="Episode ID">
                <p>{caseProfile.episodeId}</p>
              </DetailItem>
              <DetailItem label="Member Name">
                <p className="capitalize">
                  {caseProfile.memberFirstName} {caseProfile.memberLastName}
                </p>
              </DetailItem>
              <DetailItem label="Member DOB">
                <p>{caseProfile.memberDob}</p>
              </DetailItem>
              <DetailItem label="Member State">
                <p>{caseProfile.memberState.name}</p>
              </DetailItem>
              <DetailItem label="Health Plan">
                <p>{caseProfile.healthPlan.name}</p>
              </DetailItem>
              <DetailItem label="Member ID">
                <p>{caseProfile.memberMembershipNumber}</p>
              </DetailItem>
              <DetailItem label="Case Type">
                <p>{caseProfile.modality.name}</p>
              </DetailItem>
              <DetailItem label="Level of Review">
                <p>{levelOfReviewLabel(caseProfile) || "-"}</p>
              </DetailItem>
            </DetailList>
          </div>
        ))}
      </div>
    </section>
  );
};

function levelOfReviewLabel(caseProfile: CaseProfileModel): string | undefined {
  return translateLOR(
    caseProfile.levelOfReview.label,
    caseProfile.insurancePlanCode,
    caseProfile.memberState.name
  );
}

/**
 * AppointmentRow.
 */

export const statusLabels: Record<AppointmentStatus, string> = {
  [AppointmentStatus.Cancelled]: "Cancelled",
  [AppointmentStatus.Completed]: "Completed",
  [AppointmentStatus.Missed]: "Missed",
  [AppointmentStatus.Scheduled]: "Scheduled",
};

const statusIcons: Record<AppointmentStatus, IconProp> = {
  [AppointmentStatus.Cancelled]: ["far", "calendar-times"],
  [AppointmentStatus.Completed]: ["far", "calendar-check"],
  [AppointmentStatus.Missed]: ["far", "calendar-times"],
  [AppointmentStatus.Scheduled]: ["far", "calendar-alt"],
};

const statusClassNames = {
  [AppointmentStatus.Cancelled]: {
    outer: "border-red-600 hover:bg-red-50",
    iconContainer: "bg-red-600",
    label: "text-red-600",
  },
  [AppointmentStatus.Missed]: {
    outer: "border-red-600 hover:bg-red-50",
    iconContainer: "bg-red-600",
    label: "text-red-600",
  },
  [AppointmentStatus.Completed]: {
    outer: "border-green-600 hover:bg-green-50",
    iconContainer: "bg-green-600",
    label: "text-green-600",
  },
  [AppointmentStatus.Scheduled]: {
    outer: "border-blue-600 hover:bg-blue-50",
    iconContainer: "bg-blue-600",
    label: "text-blue-600",
  },
};

interface AppointmentRowProps {
  appointment: AppointmentModel;
  onClick(): void;
}

const AppointmentRow: FC<AppointmentRowProps> = (props) => {
  const { appointment, onClick } = props;
  const status = appointmentStatus(appointment);
  const statusLabel = statusLabels[status];
  const statusIcon = statusIcons[status];

  const dateTime = `${appointment.startDateString} - ${appointment.startTimeString}`;
  const classNames = statusClassNames[status];

  return (
    <div
      className={`_AppointmentRow ${classNames.outer} relative bg-white border cursor-pointer duration-100 ease-in-out flex items-center hover:-translate-y-1 hover:shadow-xl mb-3 overflow-hidden rounded-lg shadow-md shadow-sm transform transition-all translate-y-0`}
      onClick={onClick}
    >
      <div
        className={`${classNames.iconContainer} flex items-center px-4 text-2xl text-white`}
      >
        <FAIcon icon={statusIcon} />
      </div>
      <div
        className={`absolute h-full ${classNames.iconContainer} flex items-center px-4 text-2xl text-white`}
      >
        <FAIcon icon={statusIcon} />
      </div>
      <div className="flex-1 px-4 py-2">
        <p className="font-semibold text-gray-900">
          <span className="mr-2">
            <FAIcon icon={["far", "clock"]} />
          </span>
          {dateTime}
        </p>
        <p className="text-gray-900">
          <span className="mr-2 text-gray-600">
            <FAIcon icon={"user-md"} />
          </span>
          {appointment.receivingProvider.nameWithAppellation}
        </p>
      </div>
      <p className={`${classNames.label} font-bold px-3 text-sm uppercase`}>
        {statusLabel}
      </p>
    </div>
  );
};

/**
 * SchedulingPanel.
 */
interface SchedulingPanelProps {
  appointmentRequest: AppointmentRequestModel;
  openViewAppointment(appointment: AppointmentModel): void;
}

const SchedulingPanel: FC<SchedulingPanelProps> = (props) => {
  const { appointmentRequest, openViewAppointment } = props;
  const { appointments } = appointmentRequest;

  const sortedAppointments = sortByNewest(appointments) as AppointmentModel[];

  const currentAppointments = sortedAppointments.filter(
    (appt) => appointmentIsScheduled(appt) && !appointmentShouldBeFinished(appt)
  );

  const previousAppointments = sortedAppointments.filter(
    (appt) => !appointmentIsScheduled(appt) || appointmentShouldBeFinished(appt)
  );

  return (
    <section className="p-4 bg-white border rounded-lg shadow-xl">
      <div className="flex items-center">
        <CircleIcon icon="calendar-alt" />
        <h3 className="ml-3 text-xl text-gray-700">Scheduling</h3>
      </div>

      {currentAppointments.length > 0 && (
        <div className="mt-4">
          <h5 className="mb-2 text-lg font-semibold text-gray-800">
            Scheduled
          </h5>
          {currentAppointments.map((appt) => (
            <AppointmentRow
              key={appt.id}
              appointment={appt}
              onClick={() => openViewAppointment(appt)}
            />
          ))}
        </div>
      )}

      {previousAppointments.length > 0 && (
        <div className="mt-4">
          <h5 className="mb-2 text-lg font-semibold text-gray-800">Previous</h5>
          {previousAppointments.map((appt) => (
            <AppointmentRow
              key={appt.id}
              appointment={appt}
              onClick={() => openViewAppointment(appt)}
            />
          ))}
        </div>
      )}
    </section>
  );
};

/**
 * ActivityPanel.
 */
interface ActivityPanelProps {
  appointmentRequest: AppointmentRequestModel;
}

const ActivityPanel: FC<ActivityPanelProps> = (props) => {
  const { appointmentRequest } = props;

  return (
    <section className="p-4 bg-white border rounded-lg shadow-xl">
      <div className="flex items-center">
        <CircleIcon icon="clipboard-list" />
        <h3 className="ml-3 text-xl text-gray-700">Activity</h3>
      </div>
      <div>
        {appointmentRequest.appointmentRequestEvents.map((evt) => (
          <AppointmentRequestEvent key={evt.id} event={evt} />
        ))}
      </div>
    </section>
  );
};

interface RouteParams {
  appointmentRequestId: string;
}

type ActiveModal =
  | { name: "VIEW_APPOINTMENT"; appointment: AppointmentModel }
  | { name: "CANCEL_APPOINTMENT"; appointment: AppointmentModel }
  | { name: "RESCHEDULE_APPOINTMENT"; appointment: AppointmentModel }
  | { name: "UPDATE_TIME_PREFERENCE" }
  | { name: "UPDATE_CONTACT_DETAILS" };

interface P2PRequestShowScreenProps {}

export const P2PRequestShowScreen: FC<P2PRequestShowScreenProps> = () => {
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const closeModal = useCallback(() => {
    setActiveModal(null);
  }, [setActiveModal]);

  const openViewAppointment = useCallback(
    (appointment: AppointmentModel) => {
      setActiveModal({ name: "VIEW_APPOINTMENT", appointment });
    },
    [setActiveModal]
  );

  const openCancelAppointment = useCallback(
    (appointment: AppointmentModel) => {
      setActiveModal({ name: "CANCEL_APPOINTMENT", appointment });
    },
    [setActiveModal]
  );

  const openRescheduleAppointment = useCallback(
    (appointment: AppointmentModel) => {
      setActiveModal({ name: "RESCHEDULE_APPOINTMENT", appointment });
    },
    [setActiveModal]
  );

  const match = useRouteMatch<RouteParams>();
  const { appointmentRequestId } = match.params;

  const { data, loading, error } = useQuery<Data>(
    APPOINTMENT_REQUEST_SHOW_QUERY,
    { variables: { id: appointmentRequestId } }
  );

  return (
    <div className="P2PRequestShowScreen p-4">
      <ScreenTitle title={["P2P Requests", "View P2P Request"]} />

      {loading ? (
        <div className="p-8 text-center">
          <Spinner />
        </div>
      ) : error || !(data && data.appointmentRequest) ? (
        <>
          <p>Failed to load.</p>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </>
      ) : (
        <div className="md:flex mt-4 mb-8 -mx-2">
          <div className="flex-1 mx-2">
            <P2PRequestPanel appointmentRequest={data.appointmentRequest} />
          </div>
          <div className="flex-1 mx-2">
            <div className="md:mt-0 mt-4">
              <SchedulingPanel
                appointmentRequest={data.appointmentRequest}
                openViewAppointment={openViewAppointment}
              />
            </div>
            <div className="mt-4">
              <ActivityPanel appointmentRequest={data.appointmentRequest} />
            </div>
          </div>
        </div>
      )}

      <ViewAppointmentModal
        isOpen={activeModal?.name === "VIEW_APPOINTMENT"}
        onClose={closeModal}
        appointmentRequest={data?.appointmentRequest}
        appointment={
          (activeModal?.name === "VIEW_APPOINTMENT" &&
            activeModal?.appointment) ||
          undefined
        }
        openCancelAppointment={openCancelAppointment}
        openRescheduleAppointment={openRescheduleAppointment}
      />

      <CancelAppointmentModal
        isOpen={activeModal?.name === "CANCEL_APPOINTMENT"}
        onClose={closeModal}
        appointment={
          (activeModal?.name === "CANCEL_APPOINTMENT" &&
            activeModal?.appointment) ||
          undefined
        }
        refetchQueries={[
          {
            query: APPOINTMENT_REQUEST_SHOW_QUERY,
            variables: { id: appointmentRequestId },
          },
        ]}
      />

      <RescheduleAppointmentModal
        isOpen={activeModal?.name === "RESCHEDULE_APPOINTMENT"}
        onClose={closeModal}
        appointmentRequestId={appointmentRequestId}
        appointment={
          (activeModal?.name === "RESCHEDULE_APPOINTMENT" &&
            activeModal.appointment) ||
          undefined
        }
        refetchQueries={[
          {
            query: APPOINTMENT_REQUEST_SHOW_QUERY,
            variables: { id: appointmentRequestId },
          },
        ]}
      />
    </div>
  );
};

export function displayPhoneNumber(
  phoneNumber: string,
  ext?: string | null
): string {
  if (ext) {
    return `${phoneNumber} ext. ${ext}`;
  }
  return phoneNumber;
}
