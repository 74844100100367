import React, { FC } from "react";
import { useField, ErrorMessage } from "formik";
import { PreferredTimes, TimeSet } from "components/PreferredTimes";
import { VerticalField, HorizontalField } from "@ovicare/ui";

/**
 * PreferredTimesInput.
 */

interface PreferredTimesInputProps {
  id?: string;
  name: string;
  times?: TimeSet[];
  showInstructions?: boolean;
}

export const PreferredTimesInput: FC<PreferredTimesInputProps> = props => {
  const { id, name, times, showInstructions = true } = props;
  const [field, , helpers] = useField(name);
  const { value } = field;
  const { setValue } = helpers;

  return (
    <>
      <PreferredTimes
        id={id || name}
        selectedTimes={value}
        onChange={setValue}
        times={times}
        showInstructions={showInstructions}
      />
      <ErrorMessage component="p" name={name} className="help is-danger" />
    </>
  );
};

/**
 * PreferredTimesField.
 */

interface PreferredTimesFieldProps extends PreferredTimesInputProps {
  label: string;
  indicateOptional?: boolean;
}

export const PreferredTimesField: FC<PreferredTimesFieldProps> = props => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <PreferredTimesInput {...rest} />
    </VerticalField>
  );
};

export const HorizontalPreferredTimesField: FC<PreferredTimesFieldProps> = props => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <PreferredTimesInput {...rest} />
    </HorizontalField>
  );
};
