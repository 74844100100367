import React, { FC, useLayoutEffect } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  useHistory
} from "react-router-dom";
import { OldSignInScreen } from "./screens/OldSignInScreen";
import { SignInScreen } from "./screens/SignInScreen";
import { useAuth } from "context/AuthContext";

interface UnauthenticatedAppProps {}

export const UnauthenticatedApp: FC<UnauthenticatedAppProps> = () => {
  const match = useRouteMatch();

  const { token } = useAuth();
  const location = useLocation();
  const history = useHistory();

  const { from } = (location.state as any) || {
    from: { pathname: "/cw/p2p_request_wizard/new" }
  };

  useLayoutEffect(() => {
    if (token) {
      history.replace(from);
    }
  }, [history, token, from]);

  return (
    <div className="_UnauthenticatedApp min-w-screen min-h-screen">
      <Switch>
        <Route path={`${match.path}/old_sign_in`}>
          <OldSignInScreen />
        </Route>
        <Route path={`${match.path}/sign_in`}>
          <SignInScreen />
        </Route>
      </Switch>
    </div>
  );
};
