import React, { FC, useCallback } from "react";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuth } from "context/AuthContext";
import { FormStatusErrors } from "components/formik/FormStatusErrors";

const LOGIN_AGENT = gql`
  mutation LoginAgent($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      errors {
        key
        message
      }
      session {
        token
        agent {
          id
          firstName
          timeZoneName
          lastSignInAt
        }
      }
    }
  }
`;

interface MutationData {
  login: {
    errors?: InputError[];
    session?: {
      token: string;
      agent: {
        id: string;
        firstName?: string;
        timeZoneName?: string;
        lastSignInAt: string;
      };
    };
  };
}

interface MutationVariables {
  email: string;
  password: string;
}

interface FormValues {
  email: string;
  password: string;
}

interface SignInFormProps {
  refetchQueries?: any;
}

export const OldSignInForm: FC<SignInFormProps> = () => {
  const [signIn] = useMutation<MutationData, MutationVariables>(LOGIN_AGENT);
  const { login } = useAuth();

  const onSubmit = useCallback(
    (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });
      signIn({
        variables: values
      }).then(
        resp => {
          if (resp?.data?.login.errors) {
            setStatus({ errors: resp.data.login.errors });
          } else if (resp?.data?.login?.session?.token) {
            // it worked
            return login(resp.data.login.session.token);
          }
          setSubmitting(false);
        },
        rej => {
          setStatus({
            errors: [{ key: "", message: "Something went wrong." }]
          });
          setSubmitting(false);
        }
      );
    },
    [signIn, login]
  );

  return (
    <div className="_OldSignInForm bg-white py-8 px-4 shadow-xl sm:rounded-2xl sm:px-10">
      <Formik<FormValues>
        initialValues={{
          email: "",
          password: ""
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .trim()
            .required("Required"),
          password: Yup.string().required("Required")
        })}
        onSubmit={onSubmit}
      >
        {({ status, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <Field
                  className="form-input w-full"
                  name="email"
                  placeholder="Email"
                  autoFocus
                />
              </div>
              <ErrorMessage
                component="p"
                name="email"
                className="mt-2 text-red-500 text-xs italic"
              />
            </div>

            <div className="mt-4">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Password
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <Field
                  className="form-input w-full"
                  name="password"
                  type="password"
                  placeholder="Password"
                />
              </div>
              <ErrorMessage
                component="p"
                name="password"
                className="mt-2 text-red-500 text-xs italic"
              />
            </div>
            <div className="mt-6">
              <span className="block w-full rounded-md shadow-sm">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gold-600 hover:bg-gold-500 focus:outline-none focus:border-gold-700 focus:shadow-outline-yellow active:bg-gold-700 transition duration-150 ease-in-out"
                >
                  Sign in
                </button>
              </span>
            </div>
            <div className="flex items-center justify-end mt-3 py-1"></div>
          </form>
        )}
      </Formik>
    </div>
  );
};
