import React, { FC } from "react";
import { AppointmentModel } from "../types";
import { Modal, ModalHeader } from "components/Modal";
import { CancelAppointmentForm } from "./CancelAppointmentForm";
import { QuickAppointmentDetails } from "../QuickAppointmentDetails";

interface CancelAppointmentModalProps {
  onClose(): void;
  isOpen: boolean;
  appointment?: AppointmentModel;
  refetchQueries: any;
}

export const CancelAppointmentModal: FC<CancelAppointmentModalProps> = props => {
  const { isOpen, onClose, appointment, refetchQueries } = props;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="max-w-2xl">
      <ModalHeader icon="calendar-alt" title="Cancel Appointment" color="red" />
      <div className="_CancelAppointmentModal px-4 pb-5">
        {appointment && (
          <div className="flex justify-center">
            <QuickAppointmentDetails appointment={appointment} />
          </div>
        )}
        <div className="mt-4">
          {appointment && (
            <CancelAppointmentForm
              appointment={appointment}
              refetchQueries={refetchQueries}
              onSuccess={onClose}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};
