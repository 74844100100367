export function icdRequired(
  stateName: string,
  healthPlanType: string
): boolean {
  const texasRule = stateName === 'Texas';
  const oklahomaRule =
    stateName === 'Oklahoma' && healthPlanType === 'Medicaid';
  const louisianaRule =
    stateName === 'Louisiana' && healthPlanType === 'Commercial';
  const arkansasRule =
    stateName === 'Arkansas' && healthPlanType === 'Commercial';

  return texasRule || oklahomaRule || louisianaRule || arkansasRule;
}
