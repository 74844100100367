import React, { FC, useState, useCallback } from "react";
import { useLocation, useRouteMatch, useHistory } from "react-router-dom";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import qs from "query-string";
import { parseISO, lightFormat } from "date-fns";
import { ddd, firstSecondThird, translateLOR } from "@ovicare/common";
import {
  FAIcon,
  CircleIcon,
  DetailList,
  DetailItem,
  CopyText,
} from "@ovicare/ui";
import { NoLongerAvailableModal } from "./NoLongerAvailableModal";
import { ConfirmationForm } from "./ConfirmationForm";

const CONFIRMATION_PAGE_QUERY = gql`
  query getConfirmationPageData(
    $appointmentRequestId: UUID4!
    $providerId: UUID4!
  ) {
    appointmentRequest(id: $appointmentRequestId) {
      id
      caseProfiles {
        id
        memberFirstName
        memberLastName
        memberDob
        memberMembershipNumber
        caseReferenceNumber
        episodeId
        externalSystemName
        insurancePlanCode
        levelOfReview {
          value
          label
        }
        healthPlan {
          id
          name
        }
        modality {
          id
          name
        }
        memberState {
          id
          name
        }
      }
    }
    provider(id: $providerId) {
      id
      firstName
      lastName
      nameWithAppellation
      skills {
        id
        name
      }
    }
  }
`;

interface Data {
  appointmentRequest: {
    id: string;
    caseProfiles: CaseProfile[];
  };
  provider: {
    id: string;
    firstName: string;
    lastName: string;
    nameWithAppellation: string;
    skills: {
      id: string;
      name: string;
    }[];
  };
}

interface CaseProfile {
  id: string;
  memberFirstName: string;
  memberLastName: string;
  memberDob: string;
  memberMembershipNumber: string;
  caseReferenceNumber: string;
  episodeId?: string;
  externalSystemName: string;
  insurancePlanCode?: string;
  levelOfReview: {
    value: string;
    label: string;
  };
  healthPlan: {
    id: string;
    name: string;
  };
  modality: {
    id: string;
    name: string;
  };
  memberState: {
    id: string;
    name: string;
  };
}

/**
 * AppointmentInfo.
 */
interface AppointmentInfoProps {
  startTimeString: string;
  startTime: string;
  providerName: string;
}

const AppointmentInfo: FC<AppointmentInfoProps> = (props) => {
  const { startTimeString, startTime, providerName } = props;

  const start = parseISO(startTime);
  return (
    <div className="_AppointmentInfo mt-4">
      <DetailList>
        <DetailItem label="Date">
          <span className="mr-2">
            <FAIcon icon={["far", "calendar-alt"]} />
          </span>
          {formatDate(start)}
        </DetailItem>
        <DetailItem label="Time">
          <span className="mr-2">
            <FAIcon icon={["far", "clock"]} />
          </span>
          {startTimeString}
        </DetailItem>
        <DetailItem label="Reviewing Provider">
          <span className="mr-2">
            <FAIcon icon="user-md" />
          </span>
          {providerName}
        </DetailItem>
      </DetailList>
    </div>
  );
};

interface RouteParams {
  appointmentRequestId: string;
}

interface ConfirmationStepProps {}

export const ConfirmationStep: FC<ConfirmationStepProps> = () => {
  const history = useHistory();
  const [noLongerAvailable, setNoLongerAvailable] = useState(false);

  const flashNoLongerAvailable = useCallback(() => {
    setNoLongerAvailable(true);
  }, [setNoLongerAvailable]);

  const dismissNoLongerAvailable = useCallback(() => {
    setNoLongerAvailable(false);
  }, [setNoLongerAvailable]);

  const acknowledgeNoLongerAvailable = useCallback(() => {
    dismissNoLongerAvailable();
    return history.goBack();
  }, [history, dismissNoLongerAvailable]);

  const location = useLocation();
  const { startTime, startTimeString, finishTime, providerId } = qs.parse(
    location.search
  );
  const match = useRouteMatch<RouteParams>();
  const { appointmentRequestId } = match.params;

  const { data, loading, error } = useQuery<Data>(CONFIRMATION_PAGE_QUERY, {
    variables: { appointmentRequestId, providerId },
  });

  return (
    <div className="ConfirmationStep mb-8 mt-4">
      <NoLongerAvailableModal
        isOpen={noLongerAvailable}
        onRequestClose={dismissNoLongerAvailable}
        onAcknowledge={acknowledgeNoLongerAvailable}
      />
      {loading ? (
        <p>Loading...</p>
      ) : error || !(data && data.appointmentRequest && data.provider) ? (
        <p>Failed to load.</p>
      ) : (
        <div className="flex -mx-2">
          <div className="flex-1 max-w-sm mx-2 p-2 bg-white rounded-xl border shadow-xl">
            <section>
              <h3 className="font-bold mt-4 mx-4 text-2xl text-gray-800">
                P2P Info
              </h3>
              <div className="px-4">
                <AppointmentInfo
                  startTime={startTime as string}
                  startTimeString={startTimeString as string}
                  providerName={data.provider.nameWithAppellation}
                />
              </div>
            </section>
            <section className="mt-6">
              <h3 className="font-bold mt-4 mx-4 text-2xl text-gray-800">
                Case Info
              </h3>
              <div className="mt-4">
                {data.appointmentRequest.caseProfiles.map(
                  (caseProfile, idx) => (
                    <div
                      key={caseProfile.id}
                      className="border m-2 p-3 pb-8 rounded rounded-lg shadow-md"
                    >
                      <p className="text-lg text-gray-700">
                        {firstSecondThird(idx + 1)} Case
                      </p>
                      <DetailList className="mt-4">
                        <DetailItem label="Case #">
                          <CopyText text={caseProfile.caseReferenceNumber} />
                        </DetailItem>
                        <DetailItem label="Episode ID">
                          <p>{caseProfile.episodeId || "-"}</p>
                        </DetailItem>
                        <DetailItem label="Member Name">
                          <p className="capitalize">
                            {caseProfile.memberFirstName}{" "}
                            {caseProfile.memberLastName}
                          </p>
                        </DetailItem>
                        <DetailItem label="Member DOB">
                          <p>{caseProfile.memberDob}</p>
                        </DetailItem>
                        <DetailItem label="Member State">
                          <p>{caseProfile.memberState.name}</p>
                        </DetailItem>
                        <DetailItem label="Health Plan">
                          <p>{caseProfile.healthPlan.name}</p>
                        </DetailItem>
                        <DetailItem label="Member ID">
                          <p>{caseProfile.memberMembershipNumber}</p>
                        </DetailItem>
                        <DetailItem label="Case Type">
                          <p>{caseProfile.modality.name}</p>
                        </DetailItem>
                        <DetailItem label="Level of Review">
                          <p>{levelOfReviewLabel(caseProfile) || "-"}</p>
                        </DetailItem>
                      </DetailList>
                    </div>
                  )
                )}
              </div>
            </section>
          </div>
          <div className="flex-1 mx-2 p-4 bg-white rounded-xl border shadow-xl">
            <div className="flex items-center">
              <CircleIcon icon="phone" />
              <h3 className="text-xl ml-3">P2P Contact Details</h3>
            </div>
            <div className="mt-4">
              <ConfirmationForm
                appointmentRequestId={appointmentRequestId}
                onScheduleConflict={flashNoLongerAvailable}
                startTime={startTime as string}
                finishTime={finishTime as string}
                providerId={providerId as string}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function formatDate(date: Date): string {
  const day = ddd(date);
  return `${day} ${lightFormat(date, "M/d/yy")}`;
}

function levelOfReviewLabel(caseProfile: CaseProfile): string | undefined {
  return translateLOR(
    caseProfile.levelOfReview.label,
    caseProfile.insurancePlanCode,
    caseProfile.memberState.name
  );
}
