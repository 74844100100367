import { isBefore, parseISO } from "date-fns";
import {
  AppointmentModel,
  AppointmentRequestModel,
  AppointmentRequestsFilterModel as FilterModel,
  AppointmentRequestSubset as Subset,
  AppointmentStatus
} from "./types";

export function filterIsOpen(filter: FilterModel): boolean {
  return filter.subset === Subset.All && !filter.caseReferenceNumber;
}

export function requestShouldBeOccuring(
  appointmentRequest: AppointmentRequestModel
) {
  const currentAppointment = getCurrentAppointment(appointmentRequest);
  return appointmentShouldBeOccuring(currentAppointment);
}

export function requestShouldBeFinished(
  appointmentRequest: AppointmentRequestModel
) {
  const currentAppointment = getCurrentAppointment(appointmentRequest);
  return appointmentShouldBeFinished(currentAppointment);
}

export function requestRequiresReschedule(
  appointmentRequest: AppointmentRequestModel
) {
  return !!appointmentRequest.appointments.find(a => a.requiresReschedule);
}

export function requestIsScheduled(
  appointmentRequest: AppointmentRequestModel
) {
  const currentAppointment = getCurrentAppointment(appointmentRequest);
  return appointmentIsScheduled(currentAppointment);
}

export function requestIsCompleted(
  appointmentRequest: AppointmentRequestModel
) {
  const currentAppointment = getCurrentAppointment(appointmentRequest);
  return appointmentIsCompleted(currentAppointment);
}

export function requestIsCancelled(
  appointmentRequest: AppointmentRequestModel
) {
  const currentAppointment = getCurrentAppointment(appointmentRequest);
  return appointmentIsCancelled(currentAppointment);
}

export function requestIsMissed(appointmentRequest: AppointmentRequestModel) {
  const currentAppointment = getCurrentAppointment(appointmentRequest);
  return appointmentIsMissed(currentAppointment);
}

export function getCurrentAppointment(
  appointmentRequest: AppointmentRequestModel
) {
  return sortByNewest(appointmentRequest.appointments)[0];
}

export function sortByNewest(appointments: AppointmentModel[]) {
  return appointments.slice(0).sort(recentlyScheduledDesc);
}

export function recentlyScheduledDesc(
  appt1: AppointmentModel,
  appt2: AppointmentModel
) {
  return appt2.insertedAt.localeCompare(appt1.insertedAt);
}

export function appointmentShouldBeOccuring(appointment: AppointmentModel) {
  const start = parseISO(appointment.timeRange.start);
  const finish = parseISO(appointment.timeRange.finish);
  const now = new Date();
  return isBefore(start, now) && isBefore(now, finish);
}

export function appointmentShouldBeFinished(appointment: AppointmentModel) {
  const finish = parseISO(appointment.timeRange.finish);
  const now = new Date();
  return isBefore(finish, now);
}

export function appointmentIsScheduled(appointment: AppointmentModel) {
  return (
    !appointment.cancelled && !appointment.missed && !appointment.completed
  );
}

export function appointmentIsCompleted(appointment: AppointmentModel) {
  return !!appointment.completed;
}

export function appointmentIsCancelled(appointment: AppointmentModel) {
  return !!appointment.cancelled;
}

export function appointmentIsMissed(appointment: AppointmentModel) {
  return !!appointment.missed;
}

export function appointmentStatus(appointment: AppointmentModel) {
  if (appointmentIsCompleted(appointment)) {
    return AppointmentStatus.Completed;
  } else if (appointmentIsMissed(appointment)) {
    return AppointmentStatus.Missed;
  } else if (appointmentIsCancelled(appointment)) {
    return AppointmentStatus.Cancelled;
  } else {
    return AppointmentStatus.Scheduled;
  }
}
