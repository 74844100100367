import React, { FC } from "react";
import { AppointmentModel } from "./types";
import {
  appointmentIsCancelled,
  appointmentIsMissed,
  appointmentIsScheduled,
  appointmentIsCompleted
} from "./helpers";

interface StatusBadgeProps {
  appointment: AppointmentModel;
  className?: string;
}

export const StatusBadge: FC<StatusBadgeProps> = props => {
  const { appointment, className } = props;
  return (
    <span
      className={`_StatusBadge font-bold rounded-full ${classNameFor(
        appointment
      )} ${className || ""}`}
    >
      {label(appointment)}
    </span>
  );
};

function classNameFor(appointment: AppointmentModel): string {
  if (appointmentIsCancelled(appointment) || appointmentIsMissed(appointment)) {
    return "bg-red-100 text-red-800";
  } else if (appointmentIsCompleted(appointment)) {
    return "bg-green-100 text-green-800";
  } else if (appointmentIsScheduled(appointment)) {
    return "bg-blue-100 text-blue-800";
  }
  // should never happen...
  return "";
}

function label(appointment: AppointmentModel): string {
  if (appointmentIsCancelled(appointment)) {
    return "Cancelled";
  } else if (appointmentIsMissed(appointment)) {
    return "Missed";
  } else if (appointmentIsCompleted(appointment)) {
    return "Completed";
  } else if (appointmentIsScheduled(appointment)) {
    return "Scheduled";
  }
  // should never happen...
  return "";
}
