import React, { forwardRef, HTMLAttributes } from 'react';
import styled from 'styled-components';

const TableEl = styled.table`
  overflow: hidden;

  & th:first-child,
  & td:first-child {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  & tr {
    transition: background-color 150ms;
  }
`;

/**
 * Table.
 */

type TableProps = HTMLAttributes<HTMLTableElement>;

export const Table = forwardRef<HTMLTableElement, TableProps>((props, ref) => {
  const { className, ...rest } = props;
  return (
    <TableEl
      className={`Table bg-gray-400 border border-collapse border-gray-400 rounded-lg shadow-md text-left w-full ${className ??
        ''}`}
      ref={ref}
      {...rest}
    />
  );
});

/**
 * THead.
 */

type THeadProps = HTMLAttributes<HTMLTableSectionElement>;

export const THead = forwardRef<HTMLTableSectionElement, THeadProps>(
  (props, ref) => {
    const { className, ...rest } = props;
    return (
      <thead
        className={`THead bg-gray-200 border-b-2 border-gray-400 font-semibold text-gray-700 text-sm tracking-wide uppercase ${className ??
          ''}`}
        ref={ref}
        {...rest}
      />
    );
  }
);

/**
 * TBody.
 */

type TBodyProps = HTMLAttributes<HTMLTableSectionElement>;

export const TBody = forwardRef<HTMLTableSectionElement, TBodyProps>(
  (props, ref) => {
    const { className, ...rest } = props;
    return <tbody className={className} ref={ref} {...rest} />;
  }
);

/**
 * TR.
 */

type TRProps = HTMLAttributes<HTMLTableRowElement>;

export const TR = forwardRef<HTMLTableRowElement, TRProps>((props, ref) => {
  const { className, ...rest } = props;
  return (
    <tr
      className={`TR bg-white border border-gray-400 hover:bg-blue-100 ${className ??
        ''}`}
      ref={ref}
      {...rest}
    />
  );
});

/**
 * TH.
 */

type THProps = HTMLAttributes<HTMLTableHeaderCellElement>;

export const TH = forwardRef<HTMLTableHeaderCellElement, THProps>(
  (props, ref) => {
    const { className, ...rest } = props;
    return (
      <th className={`px-3 py-2 ${className ?? ''}`} ref={ref} {...rest} />
    );
  }
);

/**
 * TD.
 */

type TDProps = HTMLAttributes<HTMLTableCellElement>;

export const TD = forwardRef<HTMLTableCellElement, TDProps>((props, ref) => {
  const { className, ...rest } = props;
  return <td className={`px-3 py-2 ${className ?? ''}`} ref={ref} {...rest} />;
});
