import React, { ReactNode } from 'react';
import Select, { createFilter, ValueType } from 'react-select';
import { ErrorMessage, useField } from 'formik';
import {
  VerticalField,
  HorizontalField,
} from '../../FieldStructure/FieldStructure';

export interface StandardOption {
  value: string;
  label: string;
}

function defaultGetOptionLabel(option: StandardOption): string {
  return option.label;
}

function defaultGetOptionValue(option: StandardOption): string {
  return option?.value || '';
}

// NB: This will be the default, per eviCore's request. Pass param `
const filterFromStart = createFilter({ matchFrom: 'start' });
const filterFromAny = createFilter({ matchFrom: 'any' });

interface SelectInputProps<T = StandardOption> {
  id?: string;
  name: string;
  isLoading?: boolean;
  isClearable?: boolean;
  placeholder?: ReactNode;
  options: T[];
  getOptionLabel?(option: ValueType<T, false>): string;
  getOptionValue?(option: ValueType<T, false>): string;
  components?: any;
  matchFrom?: 'start' | 'any';
  disabled?: boolean;
}

export function SelectInput<T = StandardOption>(props: SelectInputProps<T>) {
  const {
    id,
    name,
    isLoading,
    isClearable,
    placeholder,
    options,
    getOptionLabel = (defaultGetOptionLabel as unknown) as (
      option: T
    ) => string,
    getOptionValue = (defaultGetOptionValue as unknown) as (
      option: T
    ) => string,
    components,
    matchFrom = 'start',
    disabled = false,
  } = props;

  const [field, , helpers] = useField(name);
  const { value } = field;
  const { setValue, setTouched } = helpers;

  const handleChange = (option: ValueType<T, false>) =>
    setValue(getOptionValue(option));
  const handleBlur = () => setTouched(true);
  const selected = options.find(o => getOptionValue(o) === value) || null;

  return (
    <>
      <Select<T>
        isLoading={isLoading}
        isClearable={isClearable}
        placeholder={placeholder}
        id={id || name}
        name={name}
        options={options}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        onChange={handleChange}
        onBlur={handleBlur}
        value={selected}
        components={components}
        filterOption={matchFrom === 'start' ? filterFromStart : filterFromAny}
        isDisabled={disabled}
      />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-xs italic text-red-500"
      />
    </>
  );
}

interface SelectFieldProps<T = StandardOption> extends SelectInputProps<T> {
  label: string;
  indicateOptional?: boolean;
}

export function SelectField<T = StandardOption>(props: SelectFieldProps<T>) {
  const { label, indicateOptional, ...rest } = props;
  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <SelectInput {...rest} />
    </VerticalField>
  );
}

export function HorizontalSelectField<T = StandardOption>(
  props: SelectFieldProps<T>
) {
  const { label, indicateOptional, ...rest } = props;
  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <SelectInput {...rest} />
    </HorizontalField>
  );
}
