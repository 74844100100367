import { FC } from "react";
import { FAIcon } from "@ovicare/ui";

interface SchedulingBlockedMessageProps {}

export const SchedulingBlockedMessage: FC<SchedulingBlockedMessageProps> = () => {
  return (
    <div className="flex flex-col items-center p-2 mt-12">
      <div className="text-4xl text-gray-400">
        <FAIcon icon="exclamation" />
      </div>
      <p className="text-gray700 max-w-2xl mt-6 mb-8 text-2xl text-center">
        This case requires special handling. Please contact our Physician
        Support Unit at 1-800-792-8744, option 1 to schedule a Peer to Peer.
      </p>
    </div>
  );
};
