import React, { FC } from "react";
import ReactModal from "react-modal";
import { X_md as XIcon } from "components/Heroicons";
import "./Modal.css";

/**
 * Modal.
 */

interface ModalProps {
  isOpen: boolean;
  onRequestClose(): void;
  className?: string;
  overlayClassName?: string;
  closeTimeoutMS?: number;
  showX?: boolean;
}

export const Modal: FC<ModalProps> = props => {
  const {
    isOpen,
    onRequestClose,
    children,
    closeTimeoutMS = 150,
    showX = true,
    className
  } = props;

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="Modal w-full px-2"
      overlayClassName="Overlay"
      closeTimeoutMS={closeTimeoutMS}
    >
      <div
        className={`_ModalContent relative mx-auto bg-white rounded-lg shadow-xl transform transition-all ${className}`}
      >
        {children}
        {showX && (
          <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              onClick={onRequestClose}
              className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
            >
              <XIcon className="h-6 w-6" />
            </button>
          </div>
        )}
      </div>
    </ReactModal>
  );
};
