import { useEffect } from 'react';
import { useField } from 'formik';

interface AutoPopulateFieldProps<T> {
  when: boolean;
  name: string;
  value: T;
  shouldOverride?: boolean;
}

export function AutoPopulateField<T = string>(
  props: AutoPopulateFieldProps<T>
): null {
  const {
    when: predicateIsMet,
    name,
    value: newValue,
    shouldOverride = false,
  } = props;
  const [field, , helpers] = useField(name);
  const { value } = field;
  const { setValue } = helpers;

  useEffect(() => {
    if (predicateIsMet && (!value || shouldOverride) && value !== newValue) {
      setValue(newValue);
    }
  }, [predicateIsMet, name, value, newValue, setValue, shouldOverride]);
  return null;
}
